import React, {Component} from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import AuthTemplate from 'templates/AuthTemplate';
import Heading from 'components/atoms/Heading/Heading';
import Input from 'components/atoms/Input/Input';
import {alerts} from 'helpers/alerts';



import Paragraph from 'components/atoms/Paragraph/Paragraph';

import Button from 'components/atoms/Button/Button';
import Alert from 'components/atoms/Alert/Alert';
import { routes } from 'routes';
import { connect } from 'react-redux';
import { activation as activationAction } from 'actions';
import { Redirect, NavLink } from 'react-router-dom';
import withContext from 'hoc/withContext';

const Styledbutton = styled(Button)`
  margin-top:30px;
  background-color: ${({ theme }) => theme.iv};
`;


 
    class ActivationPage extends Component {

        componentDidMount(){
            const { match,activation } = this.props;
           
            activation(match.params.token);
        }

   
    
        render() {

            const {alert, message, pageContext} =this.props;

            return (
                <AuthTemplate>
                
                
                        {alert ? alerts(pageContext,'alerts.',message,'success') : ''}
                            
                        { message && !alert ? ( 
                            alerts(pageContext,'alerts.',message,'')
                            )
                        : ''}
                            
                        
                    
                        <Styledbutton as={NavLink} activecolor="iv" to={routes.login} type="button" >
                            {pageContext.t(`button.signin`)}
                        </Styledbutton>
                
                </AuthTemplate>
            );
        }
    }



const mapStateToProps = ({ message ,alert}) => ({
message,alert
});

const mapDispatchToProps = dispatch => ({
    activation: (token) =>dispatch(activationAction(token)),
  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withContext(ActivationPage));
