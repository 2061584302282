import React  from 'react'

import Settings from 'views/Settings';
import EditForm from 'components/organisms/Company/EditForm';



const Edit = ({match}) => {

 


        


    return (
    
    <Settings>
  
      
        <EditForm id={match.params.id}/>
      
    </Settings>
        
    )


}


export default Edit;