import React from 'react';

import styled, { css } from 'styled-components';


const InnerWrapper = styled.div`
      display: flex;
        flex-direction: column;
    justify-content: start;
    
    padding: 10px 15px;
    
`;


export default InnerWrapper;
