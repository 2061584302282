import moment from 'moment';

export function StatusText(status) {
    let statustext='';
    if(status==2)
    {
      statustext='accepted';
    }
    else if(status==3)
    {
      statustext='rejected';
    }
    else{
      statustext='sent';
    }
  return statustext;
}

export function DateFormat(date,type,allday) {
    let string='';

    if(allday==1 && type=='end')
    {
      date=moment(date).subtract(1,"days");
    }
    else{
      date=date;
    }

   
        if(allday==1)
        {
            string=moment(date).format("YYYY-MM-DD");
        }
      
        else{
            string=moment(date).format("YYYY-MM-DD HH:mm");
        }
    
   
  return string;
}

export function DateFormatDMY(date,allday) {
  let string='';



 
      if(allday==1)
      {
          string=moment(date).format("DD-MM-YYYY");
      }
    
      else{
          string=moment(date).format("DD-MM-YYYY HH:mm");
      }
  
 
return string;
}

export function getElapsedWorkDays(start,end,type) {

  var count = 0;
  var  diff=moment(end).diff(start, 'days');
 
  var date=start;
  for (var i = 0; i <=diff; i++) {
    if(type=='saturday')
    {

      if(moment(date).isoWeekday() == 6)
      {
        
        count++;
      }
      
    }
    else if(type=='sunday')
    {
      if(moment(date).isoWeekday() == 7)
      {
        
        count++;
      }
    }
    else if(type=='sundayandsaturday')
    {
      if(moment(date).isoWeekday() == 7 || moment(date).isoWeekday() == 6)
      {
        
        count++;
      }
    }
    else{
      count=0;
    }
    
    date=moment(date).add(1,"days").format('YYYY-MM-DD');
    
  }
  return count;
}

