import React  from 'react'
import Settings from 'views/Settings';

import CreateForm from 'components/organisms/Customers/CreateCustomer';



const CreateCustomer =() =>{
        
  
    
    return (
    <Settings>
       <CreateForm />
    </Settings>
    )


}



export default CreateCustomer;