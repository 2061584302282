import React from 'react';
import styled, {css} from 'styled-components';


const Wrapper = styled.div`
 
   padding:10px;
 
`;
const Checkboxdefault = ()=>{

    return (
    <Wrapper>
    <input name="name"  type="checkbox"  
       checked
    />
  
    </Wrapper>
    )
}

   


export default Checkboxdefault;


