import React  from 'react'
import Settings from 'views/Settings';

import EventEditForm from 'components/organisms/Company/Events/EditForm';



const Edit =({match}) =>{
        

    
    return (
    <Settings>
       <EventEditForm eventid={match.params.eventid} id={match.params.id}/>
    </Settings> 
    )


}



export default Edit;