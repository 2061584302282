import {

  AUTH_SUCCESS,
  LOGOUT_SUCCESS,
  AUTH_FAILURE,
  CLEAR_MESSAGE_SUCCESS,
  REGISTER_SUCCESS,
  ACTIVATION_SUCCESS

} from 'actions';

import {  
  FETCH_SUBSCRIPTIONS_SUCCESS,
  CHECK_ORDER_STATUS_SUCCESS,
  CANCEL_ORDER_REQUEST,
  CHECK_ORDER_STATUS_REQUEST,
  CANCEL_ORDER_SUCCESS,
  FETCH_INVOICES_SUCCESS,
  FETCH_INVOICES_REQUEST
} from "actions/subscription";

  
import {
  ADD_ITEM_TO_CALENDAR_SUCCESS,
  FETCHEVENTS_SUCCESS,
  FETCHEVENTSFROMCALENDAR_SUCCESS,
  FETCHTOASTS_SUCCESS,
  UPDATETOASTS_SUCCESS,
  CHANGE_ITEM_STATUS_SUCCESS,
  CHANGE_ITEM_STATUS_REQUEST,
  FETCHEVENTSFROMCALENDAR_REQUEST,
  FETCHEVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_REQUEST,
  EDIT_EVENT_SUCCESS,
  CREATE_EVENT_SUCCESS,
  REMOVE_EVENT_SUCCESS,
  EDIT_ASSIGNED_EVENTS_SUCCESS,
  REMOVE_ITEM_ACCEPTED_SUCCESS,
  FETCH_EVENTS_LOGS_SUCCESS,
  FETCH_EVENTS_LOGS_REQUEST
} from 'actions/event';

import {
 
  GET_AUTH_USER_INFO_SUCCESS,
  ADD_USER_SUCCESS,
  FETCH_USERS_INFO_SUCCESS,
  FETCH_USERS_INFO_REQUEST,
  CHANGE_USER_INFO_SUCCESS,
  CHANGE_USER_INFO_FAILURE,
  CHANGE_USER_PASSWORD_FAILURE,
  CHANGE_USER_PASSWORD_SUCCESS,
  UPLOAD_AVATAR_SUCCESS,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_REQUEST,
  CHANGE_AUTH_USER_INFO_SUCCESS,
  CHANGE_AUTH_USER_INFO_FAILURE,
  CHANGESTATUS_USER_REQUEST,
  CHANGESTATUS_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  EDIT_USER_QUESTIONNAIRE_SUCCESS,
  NEW_CONTRACT_USER_SUCCESS,
  REMOVE_CONTRACT_USER_SUCCESS,
  REMOVE_COMPANYSTUFF_USER_SUCCESS,
  NEW_BHP_USER_SUCCESS,
  NEW_MEDICAL_USER_SUCCESS,
  EDIT_RESPONSIBILITY_USER_SUCCESS,
  NEW_COMPANYSTUFF_USER_SUCCESS,
  NEW_DOCUMENT_USER_SUCCESS,
  REMOVE_DOCUMENT_USER_SUCCESS,
  DISABLE_ACTIVE_USERS_SUCCESS

 
} from 'actions/user';

import {

  GET_COMPANY_INFO_SUCCESS,
  GET_COMPANY_INFO_REQUEST,
  UPLOAD_LOGOTYPE_SUCCESS,
  FETCH_CUSTOMERS_INFO_SUCCESS,
  FETCH_CUSTOMERS_INFO_REQUEST,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_REQUEST,
  CHANGE_SIDEBARCOLOR_SUCCESS,
  EDIT_CUSTOMER_SUCCESS,
  EDIT_SLACK_NOTIFICATION_SUCCESS
  
  

} from 'actions/company';

import {
  GET_WORKPLACES_REQUEST,
  GET_WORKPLACES_SUCCESS,
  EDIT_WORKPLACE_SUCCESS,
  REMOVE_WORKPLACE_SUCCESS,
  CREATE_WORKPLACE_SUCCESS,
  GET_PERMISSIONS_REQUEST,
  GET_PERMISSIONS_SUCCESS,
  EDIT_WORKPLACE_PERMISSION_SUCCESS
} from 'actions/workplace'

const initialState = {

  userID: localStorage.getItem('userID'),
  userAvatar: localStorage.getItem('userAvatar'),
  token: localStorage.getItem('token'),
  isLoading: false,
  events:[],
  eventsfromcalendar:[],
  toasts:[],
  message:'',
  authuserinfo:[],
  usersinfo:[],
  getuserinfo:[],
  workplaces:[],
  companyinfo:[],
  isLoadingContent:false,
  isLoadingEvents:false,
  alert:false,
  permissions:[],
  customersinfo:[],
  eventslogs:[],
  subscriptions:[],
  invoices:[],
  countusers:null,
};




const rootReducer = (state = initialState, action) => {
  switch (action.type) {


      case CLEAR_MESSAGE_SUCCESS:
        return {
          ...state,
          message: '',
          alert: false
      };

      case ACTIVATION_SUCCESS:
        return {
          ...state,
          alert: action.payload.data.alert,
          message: action.payload.data.message
        };

      case REGISTER_SUCCESS:
        return {
          ...state,
          alert: action.payload.data.alert,
          message: action.payload.data.message
        };

      case CHANGE_USER_PASSWORD_SUCCESS:
        return {
          ...state,
          message: action.payload.data.message
      };

      case DISABLE_ACTIVE_USERS_SUCCESS:
        return {
          ...state,
          countusers:action.payload.data.data
        };

      case CHANGE_USER_PASSWORD_FAILURE:
        return {
          ...state,
          message: action.error.response.data.message
      };

      case GET_PERMISSIONS_REQUEST:
        return {
          ...state,
          isLoadingContent:true
        
        };

      case GET_PERMISSIONS_SUCCESS:
        return {
          ...state,
          isLoadingContent:false,
          permissions: action.payload.data.data
        };

      case ADD_USER_SUCCESS:
        return {
          ...state,
          usersinfo:[...state.usersinfo,{...action.payload.data.data}],
          message: action.payload.data.message,
          alert:action.payload.data.alert,
      };

      case CHANGESTATUS_USER_REQUEST:
        return {
          ...state,
        
          isLoadingContent: true, 
          
        };

        case DELETE_USER_SUCCESS:
          return {
            ...state,
            alert: action.payload.data.alert,
            message: action.payload.data.message
          };

      case CHANGESTATUS_USER_SUCCESS:
        return {
          ...state,
          getuserinfo: {...state.getuserinfo,
            active: action.payload.data.data.active

          },
          alert: action.payload.data.alert,
          isLoadingContent: false, 
          message: action.payload.data.message
        };

        case EDIT_WORKPLACE_PERMISSION_SUCCESS:
          return {
            ...state,
            alert: action.payload.data.alert,
            message: action.payload.data.message
          }

      case ADD_CUSTOMER_REQUEST:
        return {
          ...state,
         
          isLoadingContent: true, 
         
      };

      case ADD_CUSTOMER_SUCCESS:
        return {
          ...state,
          alert:true,
          isLoadingContent: false, 
          customersinfo:[...state.customersinfo,{...action.payload.data.data}],
          message: action.payload.data.message,
          alert: action.payload.data.alert
      };

      case GET_WORKPLACES_SUCCESS:
        return {
            ...state,
            isLoading: false,
            isLoadingContent: false, 
            workplaces: action.payload.data.data
        };

        case CHECK_ORDER_STATUS_SUCCESS:
          return {
            ...state,
            isLoadingContent: false, 
            companyinfo: { ...state.companyinfo,
              access:  [{
                ...action.payload.data.data
               },...state.companyinfo.access.filter(item=>item.id!=action.payload.data.data.id)
             ]
              
           },
          };

          case CHECK_ORDER_STATUS_REQUEST:
            return {
                ...state,
             
                isLoadingContent: true, 
               
            };

          case CANCEL_ORDER_REQUEST:
            return {
                ...state,
             
                isLoadingContent: true, 
               
            };

          case CANCEL_ORDER_SUCCESS:
            return {
              ...state,
              isLoadingContent: false, 
              message:action.payload.data.message,
              alert: action.payload.data.alert,
              companyinfo: { ...state.companyinfo,
                access:  [{
                  ...action.payload.data.data
                 },...state.companyinfo.access.filter(item=>item.id!=action.payload.data.data.id)
               ]
                
             },
            };

        case FETCH_EVENTS_LOGS_SUCCESS:
          return {
              ...state,
           
              isLoadingContent: false, 
              eventslogs: action.payload.data.data
          };

          case FETCH_EVENTS_LOGS_REQUEST:
            return {
                ...state,
             
                isLoadingContent: true, 
             
            };

        case CREATE_WORKPLACE_SUCCESS:
          return {
              ...state,
            
              message:action.payload.data.message,
              alert: action.payload.data.alert,
              workplaces: [{...action.payload.data.data},...state.workplaces.filter(item=>item.id!=action.payload.data.data.id)]
          };

          case FETCH_INVOICES_REQUEST: 
          return {
            ...state,
            isLoadingContent: true 
           
        };

        case FETCH_INVOICES_SUCCESS: 
          return {
            ...state,
            isLoadingContent: false, 
            invoices: [...action.payload.data.data]
        };
          
        case CREATE_EVENT_SUCCESS:
          return {
              ...state,
            
              message:action.payload.data.message,
              alert: action.payload.data.alert,
              events: action.payload.data.data ? [{...action.payload.data.data},...state.events.filter(item=>item.id!=action.payload.data.data.id)] : state.events
          };

        case EDIT_WORKPLACE_SUCCESS:
          return {
              ...state,
            
              message:action.payload.data.message,
              alert: action.payload.data.alert,
              workplaces: [{...action.payload.data.data},...state.workplaces.filter(item=>item.id!=action.payload.data.data.id)]
          };

          case EDIT_EVENT_SUCCESS:
            return {
                ...state,
              
                message:action.payload.data.message,
                alert: action.payload.data.alert,
                events: [{...action.payload.data.data},...state.events.filter(item=>item.id!=action.payload.data.data.id)]
            };

          case REMOVE_WORKPLACE_SUCCESS:
            return {
                ...state,
                alert:action.payload.data.alert,
                
                message:action.payload.data.message,
              
               
            };

            case REMOVE_EVENT_SUCCESS:
              return {
                  ...state,
                  alert:action.payload.data.alert,
                  
                  message:action.payload.data.message,
                
                 
              };

        case GET_WORKPLACES_REQUEST:
          return {
              ...state,
              isLoadingContent: true
            
             
        };
  
        case GET_COMPANY_INFO_REQUEST:
          return {
              ...state,
              isLoadingContent: true
            
             
        };

        case EDIT_SLACK_NOTIFICATION_SUCCESS:
          return {
            ...state,
            message:action.payload.data.message

          };
           
        case EDIT_CUSTOMER_SUCCESS:
          return {
              ...state,
              
            
              companyinfo: action.payload.data.data,
            /*  companyinfo: { ...state.companyinfo,
                users: {...state.companyinfo.users, 
                 
                   ...  action.payload.data.data.users
                }
             },*/
              message:action.payload.data.message
        };
      
        case CHANGE_SIDEBARCOLOR_SUCCESS:
          return {
              ...state,
              
              authuserinfo: { ...state.authuserinfo,
                 company: { ...state.authuserinfo.company, 
                    sidebarcolor:   action.payload.data.data.sidebarcolor
                 }
              },
              companyinfo: {...state.companyinfo,
                sidebarcolor: action.payload.data.companyinfo.sidebarcolor

              },
              message:action.payload.data.message
        };

            
        case EDIT_USER_QUESTIONNAIRE_SUCCESS:
          return {
              ...state,
              
              getuserinfo: { ...state.getuserinfo,
                 questionnaire:  [action.payload.data.data]
                 
              },
             alert:action.payload.data.alert,
              message:action.payload.data.message
        };

        case EDIT_RESPONSIBILITY_USER_SUCCESS:
          return {
              ...state,
              
              getuserinfo: { ...state.getuserinfo,
                responsibility:  [action.payload.data.data]
                 
              },
             alert:action.payload.data.alert,
              message:action.payload.data.message
        };

        case NEW_CONTRACT_USER_SUCCESS:
          return {
              ...state,
              
              getuserinfo: { ...state.getuserinfo,
                 contracts:  [...state.getuserinfo.contracts,{...action.payload.data.data}
                ]
                 
              },
             alert:action.payload.data.alert,
              message:action.payload.data.message
        };

        case NEW_COMPANYSTUFF_USER_SUCCESS:
          return {
              ...state,
              
              getuserinfo: { ...state.getuserinfo,
                 companystuffs:  [...state.getuserinfo.companystuffs,{...action.payload.data.data}
                ]
                 
              },
             alert:action.payload.data.alert,
              message:action.payload.data.message
        };

        case NEW_DOCUMENT_USER_SUCCESS:
          return {
              ...state,
              
              getuserinfo: { ...state.getuserinfo,
                 documents:  [...state.getuserinfo.documents,{...action.payload.data.data}
                ]
                 
              },
             alert:action.payload.data.alert,
              message:action.payload.data.message
        };

        case NEW_MEDICAL_USER_SUCCESS:
          return {
              ...state,
              
              getuserinfo: { ...state.getuserinfo,
                 medicals:  [...state.getuserinfo.medicals,{...action.payload.data.data}
                ]
                 
              },
             alert:action.payload.data.alert,
              message:action.payload.data.message
        };

        case NEW_BHP_USER_SUCCESS:
          return {
              ...state,
              
              getuserinfo: { ...state.getuserinfo,
                 bhps:  [...state.getuserinfo.bhps,{...action.payload.data.data}
                ]
                 
              },
             alert:action.payload.data.alert,
              message:action.payload.data.message
        };


        case REMOVE_CONTRACT_USER_SUCCESS:
          return {
              ...state,
              
              getuserinfo: { ...state.getuserinfo,
                 contracts:  [...state.getuserinfo.contracts.filter(item=>item.id!=action.payload.data.data.id)
                ]
                 
              },
             alert:action.payload.data.alert,
              message:action.payload.data.message
        };

        case REMOVE_DOCUMENT_USER_SUCCESS:
          return {
              ...state,
              
              getuserinfo: { ...state.getuserinfo,
                 documents:  [...state.getuserinfo.documents.filter(item=>item.id!=action.payload.data.data.id)
                ]
                 
              },
             alert:action.payload.data.alert,
              message:action.payload.data.message
        };

        case REMOVE_COMPANYSTUFF_USER_SUCCESS:
          return {
              ...state,
              
              getuserinfo: { ...state.getuserinfo,
                companystuffs:  [...state.getuserinfo.companystuffs.filter(item=>item.id!=action.payload.data.data.id)
                ]
                 
              },
             alert:action.payload.data.alert,
              message:action.payload.data.message
        };
      
      case GET_COMPANY_INFO_SUCCESS:
        return {
            ...state,
            isLoadingContent: false,
            message:action.payload.data.message,
            companyinfo: action.payload.data.data
      };

      case FETCH_CUSTOMERS_INFO_REQUEST:
        return {
            ...state,
            isLoadingContent: true, 
        };
      
      case FETCH_CUSTOMERS_INFO_SUCCESS:
      return {
          ...state,
          isLoadingContent: false,
          customersinfo: action.payload.data.data
      };
     
      case  GET_AUTH_USER_INFO_SUCCESS:
      return {
          ...state,
          isLoading: false,
        //  message:action.payload.data.message,
          authuserinfo: action.payload.data.data,
          countusers:action.payload.data.activeusers
      };

     

      case GET_USER_INFO_SUCCESS:
        return {
            ...state,
            isLoading: false,
            isLoadingContent: false,
            message:action.payload.data.message,
            getuserinfo: action.payload.data.data
        };

        case GET_USER_INFO_REQUEST:
          return {
              ...state,
              isLoadingContent: true
             
        };

        case FETCH_USERS_INFO_REQUEST:
          return {
              ...state,
             
              isLoadingContent: true
             
          };

      case FETCH_USERS_INFO_SUCCESS:
      return {
          ...state,
          isLoading: false,
          isLoadingContent: false,
          //message: action.payload.data.message,
          usersinfo: [...action.payload.data.data]
      };

      case UPLOAD_AVATAR_SUCCESS:
        return {
            ...state,
            isLoading: false,
            message: action.payload.data.message,
            authuserinfo: action.payload.data.data
        };

      case UPLOAD_LOGOTYPE_SUCCESS:
          return {
              ...state,
              isLoading: false,
              companyinfo:{...state.companyinfo,
              logo:action.payload.data.data.logo
              },
              message: action.payload.data.message,
              
      };

      case CHANGE_USER_INFO_SUCCESS:
      return {
          ...state,
          isLoading: false,
          message: action.payload.data.message,
          getuserinfo: action.payload.data.data
      };

  

      case CHANGE_USER_INFO_FAILURE:
        return {
          ...state,
          message: action.error.response.data.message
      };

      case CHANGE_AUTH_USER_INFO_SUCCESS:
        return {
            ...state,
            isLoading: false,
            message: action.payload.data.message,
            authuserinfo: action.payload.data.data
        };

        case CHANGE_AUTH_USER_INFO_FAILURE:
          return {
            ...state,
            message: action.error.response.data.message
        };
   
    case FETCHEVENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingContent: false,
        isLoadingEvents:false,
        events: [...action.payload.data.data],
        message:''
      };

      case EDIT_ASSIGNED_EVENTS_SUCCESS:
        return{
          ...state,
          isLoadingContent: false,
          alert:action.payload.data.alert,
          message:action.payload.data.message,
      };

      case GET_EVENTS_REQUEST:
        return {
          ...state,
          isLoadingContent: true,
         
        };

      case GET_EVENTS_SUCCESS:
        return {
          ...state,
          isLoadingContent: false,
          events: [...action.payload.data.data],
         
        };

    case FETCHEVENTSFROMCALENDAR_REQUEST:
      return {
          ...state,
          isLoadingContent: true,
          
      };

      case FETCHEVENTS_REQUEST:
        return {
            ...state,
            isLoading: true,
            isLoadingContent: true,
            isLoadingEvents:true,
            message:''
        };

      case GET_EVENTS_REQUEST:
          return {
              ...state,
              isLoading: true,
              message:''
      };

    case FETCHEVENTSFROMCALENDAR_SUCCESS:
        return {
          ...state,
          isLoadingContent: false,
          eventsfromcalendar: [...action.payload.data.data],
          
      };
    case FETCHTOASTS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          toasts: [...action.payload.data.data],
          message:''
      };
    case UPDATETOASTS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          toasts: [...action.payload.data.data],
          message:''
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        userID: action.payload.data.user.id,
        userAvatar: action.payload.data.user.avatar_path,
        userSurname: action.payload.data.user.name,
        token:action.payload.data.token.token,
        message:'', 
        authuserinfo:action.payload.data.user 
      };

    case AUTH_FAILURE:
        return {
          ...state,
          alert:false,
          message: action.error.response.data.message
      };

    case LOGOUT_SUCCESS:
        return {
          ...state,
          message: '',
          authuserinfo:[]
      };

      

    case ADD_ITEM_TO_CALENDAR_SUCCESS:
      return {
        ...state,
        isLoadingContent: false,
        eventsfromcalendar: [...state.eventsfromcalendar,{...action.payload.data.eventsfromcalendar}],
        events:action.payload.data.events.original.data,
        message:''
      };

    case CHANGE_ITEM_STATUS_REQUEST:

      return {
        ...state,
        isLoadingContent: true,
        message:''
      };

      

    case REMOVE_ITEM_ACCEPTED_SUCCESS:
     
      return {
        ...state,
        isLoadingContent:false,
        eventsfromcalendar: [...state.eventsfromcalendar.filter(item=>item.id!=action.payload.data.data)],
        message: action.payload.data.message,
        events:action.payload.data.events.original.data,
        alert:action.payload.data.alert
    };

    case FETCH_SUBSCRIPTIONS_SUCCESS:

      return {
        ...state,
        isLoadingContent:false,
        subscriptions: action.payload.data.data
      };

    case CHANGE_ITEM_STATUS_SUCCESS:
     
      return {
        ...state,
        isLoadingContent:false,
        eventsfromcalendar: [...state.eventsfromcalendar.filter(item=>item.id!==action.payload.data.eventsfromcalendar.id),{...action.payload.data.eventsfromcalendar}],
        message: action.payload.data.message,
        alert:action.payload.data.alert,
        events:action.payload.data.events.original.data,

      };
   
    default:
      return state;
  }
};

export default rootReducer;
