import React, {Component}   from 'react'
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { Redirect, NavLink } from 'react-router-dom';
import {routes} from 'routes';
import Paragraph from 'components/atoms/Paragraph/Paragraph';
import Heading from 'components/atoms/Heading/Heading';
import Button from 'components/atoms/Button/Button';
import Input from 'components/atoms/Input/Input';
import Select from 'components/atoms/Input/Select';
import SmallSpinner from 'components/atoms/Spinner/SmallSpinner';
import Radio from 'components/atoms/Input/Radio';

import Card from 'components/molecules/Card/Card';
import HeaderWrapper from 'components/atoms/HeaderWrapper/HeaderWrapper';
import InnerWrapper from 'components/atoms/InnerWrapper/InnerWrapper';
import Buttons from 'components/atoms/Buttons/Buttons';
import trashIcon from 'assets/icons/trash.svg';
import Modal from 'components/molecules/Modal/Modal';
import { ChromePicker } from 'react-color';


import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import ButtonIcon from 'components/atoms/ButtonIcon/ButtonIcon';

import {removeEvent as removeEventAction, editEvent as editEventAction, getEvents as  getEventsAction } from 'actions/event';
import { getCompanyInfo as getCompanyInfoAction } from 'actions/company';


import {clearMessage as clearMessageAction} from 'actions';
import { connect } from 'react-redux';
import withContext from 'hoc/withContext';
import gsap from "gsap";
import {alerts} from 'helpers/alerts';

import * as Yup from 'yup'; 


const StyledWrapper = styled.div`
  display:flex;
`;




const EditUserSchema = Yup.object().shape({
    name: Yup.string()
    
      .required('required'),

    mark: Yup.string()
    .max('6',  'toolong')
      .required('required'),
     
  });
  

class AddUserForm extends Component {

  constructor(){
    super();
    this.ShowUserInfoWrap = null;
    this.FormUserInfoWrap = null;
  
    this.tlUserInfo = null;
    this.FormUserInfoWrap= null;
   
}

state={
  redirect:null,
  modalVisible:false,
  isAccept: false,
  filter:0,
  background: '#',
  name:'',
  mark:''
}

  componentDidUpdate(prevState, prevProps){

    const { id, removeEvent, eventid,eventItem, alert, message } = this.props;
    const eventlimit=eventItem && eventItem[0] && eventItem[0].limit;
    const eventcolor=eventItem && eventItem[0] && eventItem[0].borderColor;
    const eventname=eventItem && eventItem[0] && eventItem[0].name;
    const eventmark=eventItem && eventItem[0] && eventItem[0].mark;

    if(this.state.isAccept && this.state.isAccept!=prevProps.isAccept)
    {
     
      removeEvent(eventid);
    
    }

    if(message=='eventhasbeenremoved' )
    {
   
      this.setState({ redirect: `/settings/company/${id}/events` });
    }

   
    if( this.state.filter ==0 && eventlimit)
    {   
      
       
        this.setState({ 
            filter:eventlimit 
          });
        
      
    }
 

    if( prevProps.name =='' )
    { 
      if(eventname)
      {
      this.setState({ 
        name:eventname
        });
      }
    }

    if( prevProps.mark =='' )
    { 
      if(eventmark)
      {
      this.setState({ 
        mark:eventmark
        });
      }
    }

    if (eventcolor && eventcolor!== this.state.background && this.state.background=='#') 
    {
      this.setState({ background: eventcolor });
    }
    
  }

  componentDidMount(){

    const { id,   getEvents, clearMessage,  getCompanyInfo } = this.props;
    clearMessage();
    getCompanyInfo(id);
    getEvents(id);
   

    this.tlUserInfo = gsap.timeline({defaults:{ease:'power3.inOut'}})
        .set([this.FormUserInfoWrap], {autoAlpha: 0})
       .fromTo(this.FormUserInfoWrap, { y: "-100"}, {duration: 1, y:"+=100", autoAlpha:1})
   
    
    }

    showModal = (text) =>{

      this.setState ({
        messageModal: text,
        modalVisible: true,
      });
      
    }

    hideModal= () =>{
      this.setState ({
      modalVisible: false,
    });
   }
  

    acceptedAction = () =>{
      this.setState ({
        isAccept: true,
      });
    }

  
    addToFilter = (data) =>{
        this.setState ({
            filter: data
          });
       
      }

      handleChangeColorOnPicker = (color) => {
        this.setState({ background: color.hex });
      };
     
  
      handleName=(data)=>{
        this.setState({ name: data.target.value});
      }

      handleMark=(data)=>{
        this.setState({ mark: data.target.value});
      }

    render() {
       
        const { id, eventid,companyinfo, pageContext, message,isLoadingContent, authuserinfo, eventItem,clearMessage, editEvent, removeEvent, alert} =this.props;
        const {name,mark,filter, redirect, modalVisible, isAccept, background}=this.state;

        const roleAdmin = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='SuperAdmin').length;
        const roleCustomer = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='Customer').length && companyinfo.id==authuserinfo.company_id;



        if(redirect)
        {
          return <Redirect to={redirect} />;
        }

        if(message=='accessdenied')
        {
          return <Redirect to={routes.error404} />;
        }
       
        const textModal=pageContext.t('event.remove');

        return (
          

         <>
         <Modal hideModal={this.hideModal} text={textModal} message={message} error={alert} acceptedAction={this.acceptedAction} isAccept={isAccept}  isVisible={modalVisible}/>
            <StyledWrapper ref={div => this.FormUserInfoWrap = div}>
           
            { roleAdmin || roleCustomer?
            <Card pagecolor={pageContext.pageColor}>
            <HeaderWrapper  activeColor={pageContext.sidebarColor}>
              <Heading>{pageContext.t('card.eventedit')}</Heading>
              <ButtonIcon small   icon={trashIcon} onClick={this.showModal}  />
             
            </HeaderWrapper>
            {!isLoadingContent ?
            <Formik enableReinitialize
            initialValues={{ name: name, mark:mark, limit:filter,color:background}}
            validationSchema={EditUserSchema}
    
            onSubmit={values => {
            editEvent(values, eventid);
             this.setState({ redirect: `/settings/company/${id}/events` });

             
            }}
      
            >  
            {({ values, handleChange, handleBlur,errors, touched,setFieldValue }) => (
              <Form>
              <InnerWrapper  pagecolor={pageContext.pageColor}>
             
            
                <Heading medium>{pageContext.t('name')}</Heading>
                <Input card
                type="text"
                name="name"
                placeholder={values.name}
                onChange={this.handleName}
                onBlur={handleBlur}
                value={values.name} 
                className={`form-control ${
                  errors.name && touched.name ? "is-invalid" : ""
                }`}
                />
                {errors.name && touched.name ? (
                  <ErrorMessage>{pageContext.t(`card.validate.${errors.name}`)}</ErrorMessage>
                ) : null}
    
                <Heading medium>{pageContext.t('event.mark')}</Heading>
                <Input card
                type="text"
                name="mark"
                placeholder={values.mark}
                onChange={this.handleMark}
                onBlur={handleBlur}
                value={values.mark} 
                className={`form-control ${
                  errors.mark && touched.mark ? "is-invalid" : ""
                }`}
                />
                {errors.mark && touched.mark ? (
                  <ErrorMessage>{pageContext.t(`card.validate.${errors.mark}`)}</ErrorMessage>
                ) : null}

                <Heading medium>{pageContext.t('event.limit')}</Heading>
               
                <Field
                    type="radio"
                    name='limit'
                    id="1"
                    label="Tak"
                    setFieldValue={setFieldValue}
                    component={Radio}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    filter={filter}
                    addToFilter={this.addToFilter}
                    />

                <Field
                    type="radio"
                    name='limit'
                    id="0"
                    
                    label="Nie"
                    setFieldValue={setFieldValue}
                    component={Radio}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    filter={filter}
                    addToFilter={this.addToFilter}
                    />

                  <Heading medium>{pageContext.t('event.color')}</Heading>

                    <ChromePicker disableAlpha={true} color={background }
                        onChange={ this.handleChangeColorOnPicker } />

              </InnerWrapper>
              
              <Buttons pagecolor={pageContext.pageColor} activeColor={pageContext.sidebarColor}>
              
                <Button type="button" onClick={()=>clearMessage()}
                as={NavLink} to={`/settings/company/${id}/events`} medium back >
                {pageContext.t('button.back')}
                </Button>
    
                <Button type="submit" medium mediumaccept activecolor={pageContext.sidebarColor} >
                {pageContext.t('button.save')}
                </Button>
              </Buttons>
              </Form>
            )}
            </Formik>
            : <SmallSpinner activecolor={pageContext.sidebarColor} className="loader" />}
          </Card>
          :   ''}
          </StyledWrapper>
      
         </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {


  const { alert, message,events, authuserinfo, companyinfo,isLoadingContent } = state;
  
    return {
      eventItem: events.filter(item=>item.id==ownProps.eventid),
      message, events, authuserinfo, companyinfo, alert,isLoadingContent
    };
  

};

const mapDispatchToProps = dispatch => ({
  removeEvent: (id) => dispatch(removeEventAction(id)),
  editEvent: (values ,id) => dispatch(editEventAction(values, id)),
  getEvents: (id) => dispatch(getEventsAction(id)),
  getCompanyInfo: (id) =>dispatch(getCompanyInfoAction(id)),

  clearMessage: () =>dispatch(clearMessageAction())
}); 
 
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withContext(AddUserForm));