import React, {Component}   from 'react'
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { Redirect, NavLink } from 'react-router-dom';
import {routes} from 'routes';
import Paragraph from 'components/atoms/Paragraph/Paragraph';
import Heading from 'components/atoms/Heading/Heading';
import Button from 'components/atoms/Button/Button';
import Input from 'components/atoms/Input/Input';
import Select from 'components/atoms/Input/Select';
import QuestionnaireMenu from 'components/molecules/Menus/QuestionnaireMenu';
import Card from 'components/molecules/Card/Card';
import HeaderWrapper from 'components/atoms/HeaderWrapper/HeaderWrapper';
import InnerWrapper from 'components/atoms/InnerWrapper/InnerWrapper';
import Buttons from 'components/atoms/Buttons/Buttons';
import Radio from 'components/atoms/Input/RadioDisability';

import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import { getUserInfo as getUserInfoAction, editResponsibility as editResponsibilityAction } from 'actions/user';
import {clearMessage as clearMessageAction} from 'actions';
import { connect } from 'react-redux';
import withContext from 'hoc/withContext';
import gsap from "gsap";
import {alerts} from 'helpers/alerts';

import * as Yup from 'yup'; 


const StyledWrapper = styled.div`
  display:flex;
`;

const StyledHeading = styled(Heading)`
  text-align:center;
  font-weight:bold;
`;

const StyledInnerWrapper = styled(InnerWrapper)`
  flex-direction:row;
`;

const StyledTextArea = styled(Input)`
  margin: 0px 0 20px;
  border-radius: 20px;
  height: 200px;
    background-color: hsl(0, 0%, 94%);
    @media (max-width: 768px) {
      margin-bottom:20px;
       height: 100px;
     }
`;

const StyledInputWrapper = styled.div`
  flex-direction:column;
  padding:5px;
 
`;

const StyledCard=styled(Card)`
  max-width:100%;
`;

const StyledInput=styled(Input)`
  width:150px;
`;
  

class AddUserForm extends Component {

  constructor(){
    super();
    this.ShowUserInfoWrap = null;
    this.FormUserInfoWrap = null;
  
    this.tlUserInfo = null;
    this.FormUserInfoWrap= null;
   
}

state={
  redirect:null,
 
}

 

  componentDidMount(){

    const { id,getUserInfo, clearMessage } = this.props;
    clearMessage();
    getUserInfo(id);
   
    
   

    this.tlUserInfo = gsap.timeline({defaults:{ease:'power3.inOut'}})
        .set([this.FormUserInfoWrap], {autoAlpha: 0})
       .fromTo(this.FormUserInfoWrap, { y: "-100"}, {duration: 1, y:"+=100", autoAlpha:1})
   
    
    }

  

    render() {
       
        const { id,pageContext, message, authuserinfo,alert, clearMessage, getuserinfo, editResponsibility} =this.props;
        const {redirect}=this.state;

        const permission = authuserinfo && authuserinfo.workplace && authuserinfo.workplace.permissions  && authuserinfo.workplace.permissions.filter(perm=>perm.name=='Users').length;
        const roleAdmin = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='SuperAdmin').length;
        const roleCustomer = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='Customer').length;

        const responsibility = getuserinfo && getuserinfo.responsibility && getuserinfo.responsibility[0] ;
    
       
     
        if(redirect)
        {
          return <Redirect to={redirect} />;
        }

        return (
          

         
            <StyledWrapper ref={div => this.FormUserInfoWrap = div}>
            {permission || roleAdmin || roleCustomer ?
            <>
            <StyledCard pagecolor={pageContext.pageColor}>
            <HeaderWrapper  activeColor={pageContext.sidebarColor}>
              <Heading>Zakres obowiązków: {getuserinfo.name}</Heading>
              
             
            </HeaderWrapper>
            <Formik enableReinitialize
            initialValues={{ text:responsibility && responsibility.text ? responsibility.text : ''
             }}
           
    
            onSubmit={values => {
            
           editResponsibility(values,id);

             
            }}
      
            >  
            {({ values, handleChange, handleBlur,errors, touched,setFieldValue }) => (
              <Form>
              <InnerWrapper  pagecolor={pageContext.pageColor}>

              {alert ? alerts(pageContext,'alerts.',message,'success') : ''}
              <StyledTextArea
            name="text"
            as="textarea"
            onChange={handleChange}
            onBlur={handleBlur}
           
         
            value={values.text}
          />
              </InnerWrapper>
              
              <Buttons pagecolor={pageContext.pageColor} activeColor={pageContext.sidebarColor}>
              
                <Button type="button"
                as={NavLink} to={routes.settings.users.home} medium back >
                {pageContext.t('button.back')}
                </Button>
    
                <Button type="submit" medium mediumaccept activecolor={pageContext.sidebarColor} >
                {pageContext.t('button.save')}
                </Button>
              </Buttons>
              </Form>
            )}
            </Formik>
          </StyledCard>
          <QuestionnaireMenu pageContext={pageContext} authuserinfo={authuserinfo} id={id}/>
          </>
          :   ''}
          </StyledWrapper>
      
         
        )
    }
}

const mapStateToProps = state => {

  const { alert,message, workplaces, authuserinfo, getuserinfo } = state;

  return { alert,message, workplaces, authuserinfo, getuserinfo};
};

const mapDispatchToProps = dispatch => ({
    getUserInfo: (id) => dispatch(getUserInfoAction(id)),
    editResponsibility: (values,id) => dispatch(editResponsibilityAction(values,id)),
 
  clearMessage: () =>dispatch(clearMessageAction())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withContext(AddUserForm));