import React, {Component}   from 'react'
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { Redirect, NavLink } from 'react-router-dom';
import {routes} from 'routes';
import Paragraph from 'components/atoms/Paragraph/Paragraph';
import Heading from 'components/atoms/Heading/Heading';
import Button from 'components/atoms/Button/Button';
import Input from 'components/atoms/Input/Input';
import Select from 'components/atoms/Input/Select';
import QuestionnaireMenu from 'components/molecules/Menus/QuestionnaireMenu';
import Card from 'components/molecules/Card/Card';
import HeaderWrapper from 'components/atoms/HeaderWrapper/HeaderWrapper';
import InnerWrapper from 'components/atoms/InnerWrapper/InnerWrapper';
import Buttons from 'components/atoms/Buttons/Buttons';
import Radio from 'components/atoms/Input/RadioDisability';
import SmallSpinner from 'components/atoms/Spinner/SmallSpinner';
import DefaultTable from 'components/molecules/Table/DefaultTable';

import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import { getUserInfo as getUserInfoAction, newContract as newContractAction, removeContract as removeContractAction } from 'actions/user';
import {clearMessage as clearMessageAction} from 'actions';
import { connect } from 'react-redux';
import withContext from 'hoc/withContext';
import gsap from "gsap";
import {alerts} from 'helpers/alerts';

import * as Yup from 'yup'; 


const StyledWrapper = styled.div`
  display:flex;
`;

const StyledHeading = styled(Heading)`
  text-align:center;
  font-weight:bold;
`;

const StyledInnerWrapper = styled(InnerWrapper)`
  flex-direction:row;
`;

const StyledInputWrapper = styled.div`
  flex-direction:column;
  padding:5px;
 
`;

const StyledTableCard=styled(Card)`
 margin-right:250px;
  
`;


const StyledCard=styled(Card)`
  max-width:100%;
  position:absolute;
`;

const StyledInput=styled(Input)`
  width:150px;
`;
  

class AddUserForm extends Component {

  constructor(){
    super();
    this.ShowAvatarWrap = null;
    this.FormAvatarWrap = null;
    
    this.tlAvatar = null;
    this.tlAvatarForm = null;
    

    this.toggleAvatarForm = this.toggleAvatar.bind(this);
}

state={
  redirect:null,
  filter:'Cały',
  filterContracts:'Umowa o pracę na czas określony',
 
}


  componentDidMount(){

    const { id,getUserInfo, clearMessage } = this.props;
    clearMessage();
    getUserInfo(id);
   
    
   
    this.tlAvatar = gsap.timeline({defaults:{ease:'power3.inOut'}})
    .set([this.ShowAvatarWrap,  this.FormAvatarWrap], {autoAlpha: 0})
    .fromTo(this.ShowAvatarWrap, { y: "-100"}, {duration: 1, y:"+=100", autoAlpha:1})
    
    }

    toggleAvatar(){
    
      this.setState({
        isVisibleEditButton: false,
      });
      
      this.tlAvatarForm = gsap.timeline({defaults:{ease:'power3.inOut'}})
      this.tlAvatarForm.to(this.ShowAvatarWrap, {duration: 0.4,  autoAlpha:0})

      .fromTo(this.FormAvatarWrap, { y: "-100px"}, {duration: 0.5, y:"+=100px", autoAlpha:1})
      
    }
  
    hideAvatar(){
      
    
    
      this.tlAvatarForm.to(this.FormAvatarWrap, {duration: 0.4,  autoAlpha:0})
    }

    hideFormAvatar(){
      
     
      this.tlAvatarForm.to(this.FormAvatarWrap, {duration: 0.4,  autoAlpha:0})
      .fromTo(this.ShowAvatarWrap, { y: "-100px"}, {duration: 0.5, y:"+=100px", autoAlpha:1})
    }
     

    addToFilter = (data) =>{
      this.setState ({
          filter: data
        });
     
    }

    addToFilterContracts = (data) =>{
      this.setState ({
          filterContracts: data
        });
     
    }

    handleChangeValue=(data)=>{
     
      this.setState({ [data.target.name]: data.target.value});
    }
    render() {
       
        const { id,pageContext, message, authuserinfo,alert, clearMessage, getuserinfo, isLoadingContent, newContract, removeContract} =this.props;
        const {redirect, filter, filterContracts, company_name, start,end}=this.state;

        const permission = authuserinfo && authuserinfo.workplace && authuserinfo.workplace.permissions  && authuserinfo.workplace.permissions.filter(perm=>perm.name=='Users').length;
        const roleAdmin = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='SuperAdmin').length;
        const roleCustomer = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='Customer').length;

      

        const headers=[];
        headers.push("Nazwa","Etat",pageContext.t('company.name'),pageContext.t('statistics.date'),pageContext.t('table.action'))
    
        const body=[];

       
        getuserinfo  && getuserinfo.contracts && getuserinfo.contracts.map(contract=>{
      
            body.push({ contract: contract.contract_name , fulltime: contract.type_time,name:contract.company_name, date:contract.start+ "-"+contract.end, button:<Button onClick={()=>removeContract(contract.id)}  remove small>{pageContext.t('button.remove')}</Button>})
        });
     
        if(redirect)
        {
          return <Redirect to={redirect} />;
        }

        return (
          

         
            <StyledWrapper >
          

            <StyledTableCard pagecolor={pageContext.pageColor} ref={div => this.ShowAvatarWrap = div}>
            {permission || roleAdmin || roleCustomer ?
            <>
                <HeaderWrapper  activeColor={pageContext.sidebarColor}>
                    <Heading>Umowy</Heading>
                    
                
                </HeaderWrapper>
                {alert ? alerts(pageContext,'alerts.',message,'success') : ''}
                <StyledInnerWrapper  pagecolor={pageContext.pageColor}>
          
                {!isLoadingContent ? 
                <DefaultTable type="customers" headers={headers} body={body}/>
                : <SmallSpinner activecolor={pageContext.sidebarColor} className="loader" />}
        
                </StyledInnerWrapper>
                <Buttons pagecolor={pageContext.pageColor} activeColor={pageContext.sidebarColor}>
                        
                    <Button type="button"
                    as={NavLink} to={routes.settings.users.home} medium back >
                    {pageContext.t('button.back')}
                    </Button>

                    <Button  onClick={()=>{this.toggleAvatar(); clearMessage();}} medium activecolor={pageContext.sidebarColor} >
                    {pageContext.t('button.create')}
                    </Button>
            
                  
                    </Buttons>
                </>
          :   ''}
            </StyledTableCard>

            <StyledCard pagecolor={pageContext.pageColor} ref={div => this.FormAvatarWrap = div}>
            <HeaderWrapper  activeColor={pageContext.sidebarColor}>
              <Heading>Nowa umowa dla: {getuserinfo.name}</Heading>
              
             
            </HeaderWrapper>
            <Formik enableReinitialize
            initialValues={{company_name:company_name, type_time:filter,contract_name:filterContracts,start:start,end:end
             }}
           
    
            onSubmit={values => {
            
            newContract(values,id);
            this.hideFormAvatar();
             
            }}
      
            >  
            {({ values, handleChange, handleBlur,errors, touched,setFieldValue }) => (
              <Form>
              <InnerWrapper  pagecolor={pageContext.pageColor}>
             
                <StyledInnerWrapper>
                    <StyledInputWrapper>
                        <Heading medium>Na firmę</Heading>
                        <Input card
                        type="text"
                        name="company_name"
                        placeholder={values.firstname}
                        onChange={this.handleChangeValue}
                        onBlur={handleBlur}
                        value={values.company_name} 
                        
                        />
                    </StyledInputWrapper>

                    <StyledInputWrapper>
                    
                    <Heading medium>Etat:</Heading>

                        <Field
                        type="radio"
                        name='type_time'
                        id="Cały"
                        label="Cały"
                        setFieldValue={setFieldValue}
                        component={Radio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        filter={filter}
                        addToFilter={this.addToFilter}
                        />

                        <Field
                        type="radio"
                        name='type_time'
                        id="1/2"
                        label="1/2"
                        setFieldValue={setFieldValue}
                        component={Radio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        filter={filter}
                        addToFilter={this.addToFilter}
                        />

                        <Field
                        type="radio"
                        name='type_time'
                        id="1/4"
                        label="1/4"
                        setFieldValue={setFieldValue}
                        component={Radio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        filter={filter}
                        addToFilter={this.addToFilter}
                        />

                        <Field
                        type="radio"
                        name='type_time'
                        id="1/8"
                        label="1/8"
                        setFieldValue={setFieldValue}
                        component={Radio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        filter={filter}
                        addToFilter={this.addToFilter}
                        />

                      </StyledInputWrapper>

                     
                
                </StyledInnerWrapper>
                   

                <StyledInnerWrapper>

                 <StyledInputWrapper>
                    
                    <Heading medium>Umowa:</Heading>

                        <Field
                        type="radio"
                        name='contract_name'
                        id="Umowa o pracę na okres próbny"
                        label="Umowa o pracę na okres próbny"
                        setFieldValue={setFieldValue}
                        component={Radio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        filter={filterContracts}
                        addToFilter={this.addToFilterContracts}
                        />

                        <Field
                        type="radio"
                        name='contract_name'
                        id="Umowa o pracę na czas określony"
                        label="Umowa o pracę na czas określony"
                        setFieldValue={setFieldValue}
                        component={Radio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        filter={filterContracts}
                        addToFilter={this.addToFilterContracts}
                        />

                        <Field
                        type="radio"
                        name='contract_name'
                        id="Umowa o pracę na czas nieokreślony"
                        label="Umowa o pracę na czas nieokreślony"
                        setFieldValue={setFieldValue}
                        component={Radio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        filter={filterContracts}
                        addToFilter={this.addToFilterContracts}
                        />

                      <Field
                        type="radio"
                        name='contract_name'
                        id="Zlecenie"
                        label="Zlecenie"
                        setFieldValue={setFieldValue}
                        component={Radio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        filter={filterContracts}
                        addToFilter={this.addToFilterContracts}
                        />

                        <Field
                        type="radio"
                        name='contract_name'
                        id="O dzieło"
                        label="O dzieło"
                        setFieldValue={setFieldValue}
                        component={Radio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        filter={filterContracts}
                        addToFilter={this.addToFilterContracts}
                        />

                        <Field
                        type="radio"
                        name='contract_name'
                        id="Staż"
                        label="Staż"
                        setFieldValue={setFieldValue}
                        component={Radio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        filter={filterContracts}
                        addToFilter={this.addToFilterContracts}
                        />
                     


                   </StyledInputWrapper>

                   <StyledInputWrapper>
                        <Heading medium>Umowa od</Heading>
                        <Input card
                        type="text"
                        name="start"
                        placeholder={values.firstname}
                        onChange={this.handleChangeValue}
                        onBlur={handleBlur}
                        value={values.start} 
                        
                        />
                    </StyledInputWrapper>

                    <StyledInputWrapper>
                        <Heading medium>Umowa do</Heading>
                        <Input card
                        type="text"
                        name="end"
                        placeholder={values.firstname}
                        onChange={this.handleChangeValue}
                        onBlur={handleBlur}
                        value={values.end} 
                        
                        />
                    </StyledInputWrapper>



                </StyledInnerWrapper>

              </InnerWrapper>
              
              <Buttons pagecolor={pageContext.pageColor} activeColor={pageContext.sidebarColor}>
              
                  <Button type="button" onClick={()=>{this.hideFormAvatar(); clearMessage();}} back medium  >
                    {pageContext.t('button.cancel')}
                    </Button>
    
                <Button type="submit" medium mediumaccept activecolor={pageContext.sidebarColor} >
                {pageContext.t('button.save')}
                </Button>
              </Buttons>
              </Form>
            )}
            </Formik>
          </StyledCard>
       

          <QuestionnaireMenu pageContext={pageContext} authuserinfo={authuserinfo} id={id}/>
         
          </StyledWrapper>
      
         
        )
    }
}

const mapStateToProps = state => {

  const { alert,message, workplaces, authuserinfo, getuserinfo, isLoadingContent } = state;

  return { alert,message, workplaces, authuserinfo, getuserinfo, isLoadingContent};
};

const mapDispatchToProps = dispatch => ({
    getUserInfo: (id) => dispatch(getUserInfoAction(id)),
    newContract: (values,id) => dispatch(newContractAction(values,id)),
    removeContract: (id)=>dispatch(removeContractAction(id)),
 
  clearMessage: () =>dispatch(clearMessageAction())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withContext(AddUserForm));