import React  from 'react'
import Settings from 'views/Settings';




const Setting = () => {
 

    
    return (
    <Settings>
        
    </Settings>
    )


}




export default (Setting);