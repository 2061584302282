import React  from 'react'
import Settings from 'views/Settings';

import EditPerson from 'components/organisms/EditPerson/EditPerson';



const Person = () =>{
  

  
     
       
  
    return (
    <Settings>
        <EditPerson></EditPerson>
       

    </Settings>
    )


}



export default Person;