import React  from 'react'

import Settings from 'views/Settings';
import SlackForm from 'components/organisms/Company/SlackForm';



const Slack = ({match}) => {

 


        


    return (
    
    <Settings>
  
      
        <SlackForm id={match.params.id}/>
      
    </Settings>
        
    )


}


export default Slack;