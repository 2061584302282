import axios from 'axios';
import {url, OptionsAuth} from 'actions'



export const CHANGE_USER_INFO_REQUEST = 'CHANGE_USER_INFO_REQUEST';
export const CHANGE_USER_INFO_FAILURE = 'CHANGE_USER_INFO_FAILURE';
export const CHANGE_USER_INFO_SUCCESS = 'CHANGE_USER_INFO_SUCCESS';

export const CHANGE_AUTH_USER_INFO_REQUEST = 'CHANGE_AUTH_USER_INFO_REQUEST';
export const CHANGE_AUTH_USER_INFO_FAILURE = 'CHANGE_AUTH_USER_INFO_FAILURE';
export const CHANGE_AUTH_USER_INFO_SUCCESS = 'CHANGE_AUTH_USER_INFO_SUCCESS';

export const EDIT_USER_QUESTIONNAIRE_REQUEST = 'EDIT_USER_QUESTIONNAIRE_REQUEST';
export const EDIT_USER_QUESTIONNAIRE_FAILURE = 'EDIT_USER_QUESTIONNAIRE_FAILURE';
export const EDIT_USER_QUESTIONNAIRE_SUCCESS = 'EDIT_USER_QUESTIONNAIRE_SUCCESS';

export const EDIT_RESPONSIBILITY_USER_REQUEST = 'EDIT_RESPONSIBILITY_USER_REQUEST';
export const EDIT_RESPONSIBILITY_USER_FAILURE = 'EDIT_RESPONSIBILITY_USER_FAILURE';
export const EDIT_RESPONSIBILITY_USER_SUCCESS = 'EDIT_RESPONSIBILITY_USER_SUCCESS';

export const NEW_CONTRACT_USER_REQUEST = 'NEW_CONTRACT_USER_REQUEST';
export const NEW_CONTRACT_USER_FAILURE = 'NEW_CONTRACT_USER_FAILURE';
export const NEW_CONTRACT_USER_SUCCESS = 'NEW_CONTRACT_USER_SUCCESS';

export const NEW_MEDICAL_USER_REQUEST = 'NEW_MEDICAL_USER_REQUEST';
export const NEW_MEDICAL_USER_FAILURE = 'NEW_MEDICAL_USER_FAILURE';
export const NEW_MEDICAL_USER_SUCCESS = 'NEW_MEDICAL_USER_SUCCESS';

export const NEW_COMPANYSTUFF_USER_REQUEST = 'NEW_COMPANYSTUFF_USER_REQUEST';
export const NEW_COMPANYSTUFF_USER_FAILURE = 'NEW_COMPANYSTUFF_USER_FAILURE';
export const NEW_COMPANYSTUFF_USER_SUCCESS = 'NEW_COMPANYSTUFF_USER_SUCCESS';

export const NEW_DOCUMENT_USER_REQUEST = 'NEW_DOCUMENT_USER_REQUEST';
export const NEW_DOCUMENT_USER_FAILURE = 'NEW_DOCUMENT_USER_FAILURE';
export const NEW_DOCUMENT_USER_SUCCESS = 'NEW_DOCUMENT_USER_SUCCESS';

export const NEW_BHP_USER_REQUEST = 'NEW_BHP_USER_REQUEST';
export const NEW_BHP_USER_FAILURE = 'NEW_BHP_USER_FAILURE';
export const NEW_BHP_USER_SUCCESS = 'NEW_BHP_USER_SUCCESS';

export const REMOVE_CONTRACT_USER_REQUEST = 'REMOVE_CONTRACT_USER_REQUEST';
export const REMOVE_CONTRACT_USER_FAILURE = 'REMOVE_CONTRACT_USER_FAILURE';
export const REMOVE_CONTRACT_USER_SUCCESS = 'REMOVE_CONTRACT_USER_SUCCESS';

export const REMOVE_COMPANYSTUFF_USER_REQUEST = 'REMOVE_COMPANYSTUFF_USER_REQUEST';
export const REMOVE_COMPANYSTUFF_USER_FAILURE = 'REMOVE_COMPANYSTUFF_USER_FAILURE';
export const REMOVE_COMPANYSTUFF_USER_SUCCESS = 'REMOVE_COMPANYSTUFF_USER_SUCCESS';

export const REMOVE_DOCUMENT_USER_REQUEST = 'REMOVE_DOCUMENT_USER_REQUEST';
export const REMOVE_DOCUMENT_USER_FAILURE = 'REMOVE_DOCUMENT_USER_FAILURE';
export const REMOVE_DOCUMENT_USER_SUCCESS = 'REMOVE_DOCUMENT_USER_SUCCESS';


export const CHANGE_USER_PASSWORD_REQUEST = 'CHANGE_USER_PASSWORD_REQUEST';
export const CHANGE_USER_PASSWORD_FAILURE = 'CHANGE_USER_PASSWORD_FAILURE';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';

export const  GET_AUTH_USER_INFO_REQUEST = 'GET_AUTH_USER_INFO_REQUEST';
export const  GET_AUTH_USER_INFO_FAILURE = 'GET_AUTH_USER_INFO_FAILURE';
export const  GET_AUTH_USER_INFO_SUCCESS = 'GET_AUTH_USER_INFO_SUCCESS';

export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';

export const DISABLE_ACTIVE_USERS_REQUEST = 'DISABLE_ACTIVE_USERS_REQUEST';
export const DISABLE_ACTIVE_USERS_FAILURE = 'DISABLE_ACTIVE_USERS_FAILURE';
export const DISABLE_ACTIVE_USERS_SUCCESS = 'DISABLE_ACTIVE_USERS_SUCCESS';


export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

export const CHANGESTATUS_USER_REQUEST = 'CHANGESTATUS_USER_REQUEST';
export const CHANGESTATUS_USER_FAILURE = 'CHANGESTATUS_USER_FAILURE';
export const CHANGESTATUS_USER_SUCCESS = 'CHANGESTATUS_USER_SUCCESS';


export const FETCH_USERS_INFO_REQUEST = 'FETCH_USERS_INFO_REQUEST';
export const FETCH_USERS_INFO_FAILURE = 'FETCH_USERS_INFO_FAILURE';
export const FETCH_USERS_INFO_SUCCESS = 'FETCH_USERS_INFO_SUCCESS';

export const UPLOAD_AVATAR_REQUEST = 'UPLOAD_AVATAR_REQUEST';
export const UPLOAD_AVATAR_FAILURE = 'UPLOAD_AVATAR_FAILURE';
export const UPLOAD_AVATAR_SUCCESS = 'UPLOAD_AVATAR_SUCCESS';



  export const getUserInfo = (id) => (dispatch, getState) => {
    dispatch({ type: GET_USER_INFO_REQUEST });
  
    OptionsAuth(getState().token);
  
    return axios
      .get(`${url}/api/v1/user/userInfo/${id}`, {
     
        
      })
      .then(({data}) => {
     
       dispatch({
          type: GET_USER_INFO_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: GET_USER_INFO_FAILURE });
      });
  };

  export const changeUserStatus = (id) => (dispatch, getState) => {
    dispatch({ type: CHANGESTATUS_USER_REQUEST });
  
    OptionsAuth(getState().token);
  
    return axios
      .get(`${url}/api/v1/user/changestatus/${id}`, {
     
        
      })
      .then(({data}) => {
   
       dispatch({
          type: CHANGESTATUS_USER_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: CHANGESTATUS_USER_FAILURE });
      });
  };

  export const deleteUser = (id) => (dispatch, getState) => {
    dispatch({ type: DELETE_USER_REQUEST });
  
    OptionsAuth(getState().token);
  
    return axios
      .get(`${url}/api/v1/user/delete/${id}`, {
     
        
      })
      .then(({data}) => {
     console.log(data)
       dispatch({
          type: DELETE_USER_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: DELETE_USER_FAILURE });
      });
  };


  export const addUser = (values) => (dispatch, getState) => {
    dispatch({ type: ADD_USER_REQUEST });
  
    OptionsAuth(getState().token);
  
    return axios
      .post(`${url}/api/v1/user/add`, {
      
        values
        
      })
      .then(({data}) => {

       dispatch({
          type: ADD_USER_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: ADD_USER_FAILURE });
      });
  };

  export const getAuthUserInfo = () => (dispatch, getState) => {
    dispatch({ type: GET_AUTH_USER_INFO_REQUEST });
  
    OptionsAuth(getState().token);
  
    return axios
      .get(`${url}/api/v1/user/info`, {
     
        
      })
      .then(({data}) => {
    
       dispatch({
          type:  GET_AUTH_USER_INFO_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type:  GET_AUTH_USER_INFO_FAILURE });
      });
  };

  export const disableActiveUsers = (countusers,accessusers) => (dispatch, getState) => {
    dispatch({ type: DISABLE_ACTIVE_USERS_REQUEST });
  
    OptionsAuth(getState().token);
  
    return axios
      .post(`${url}/api/v1/user/disableactiveusers`, {
        countusers,
        accessusers
        
      })
      .then(({data}) => {
    console.log(data)
       dispatch({
          type:  DISABLE_ACTIVE_USERS_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type:  DISABLE_ACTIVE_USERS_FAILURE });
      });
  };

  export const fetchUsersInfo = () => (dispatch, getState) => {
    dispatch({ type: FETCH_USERS_INFO_REQUEST });
  
    OptionsAuth(getState().token);
  
    return axios
      .get(`${url}/api/v1/user/users`, {
     
        
      })
      .then(({data}) => {

       dispatch({
          type: FETCH_USERS_INFO_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: FETCH_USERS_INFO_FAILURE });
      });
  };

 

  export const uploadAvatar = (values) => (dispatch, getState) => {
    dispatch({ type: UPLOAD_AVATAR_REQUEST });

    OptionsAuth(getState().token);
    let reader=new FileReader();
    reader.readAsDataURL(values.file);
    reader.onload=(e)=>{
    
      const formData={file:e.target.result}
      return axios
      .post(`${url}/api/v1/user/uploadavatar`, 
     
        formData
      
      )
      .then(({data}) => {
     
      localStorage.setItem('userAvatar', data.data.avatar);
        dispatch({
           type: UPLOAD_AVATAR_SUCCESS,
           payload: {
            
             data,
           },
         });
       })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: UPLOAD_AVATAR_FAILURE, error: err });
      });
    }
   
    
  };

  export const editUserInfo = (values,id) => (dispatch, getState) => {
    dispatch({ type: CHANGE_USER_INFO_REQUEST });
  
    OptionsAuth(getState().token);

    return axios
      .post(`${url}/api/v1/user/editinfo/${id}`, {
     
        values,
        id
      })
      .then(({data}) => {
    
       dispatch({
          type: CHANGE_USER_INFO_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: CHANGE_USER_INFO_FAILURE, error: err });
      });
  };

  export const editAuthUserInfo = (values) => (dispatch, getState) => {
    dispatch({ type: CHANGE_AUTH_USER_INFO_REQUEST });
  
    OptionsAuth(getState().token);

    return axios
      .post(`${url}/api/v1/user/editauthinfo`, {
     
        values
        
      })
      .then(({data}) => {
    
       dispatch({
          type: CHANGE_AUTH_USER_INFO_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: CHANGE_AUTH_USER_INFO_FAILURE, error: err });
      });
  };

  export const changeUserPassword = (values) => (dispatch, getState) => {
    dispatch({ type: CHANGE_USER_PASSWORD_REQUEST });
  
    OptionsAuth(getState().token);

    return axios
      .post(`${url}/api/v1/user/changepassword`, {
     
        values
      })
      .then(({data}) => {
        //console.log( data)
       dispatch({
          type: CHANGE_USER_PASSWORD_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: CHANGE_USER_PASSWORD_FAILURE, error: err });
      });
  };

  
  export const editQuestionnaire = (values, id) => (dispatch, getState) => {
    dispatch({ type: EDIT_USER_QUESTIONNAIRE_REQUEST });
  
    OptionsAuth(getState().token);

    return axios
      .post(`${url}/api/v1/user/editquestionnaire/${id}`, {
     
        values
      })
      .then(({data}) => {
       // console.log( data)
       dispatch({
          type: EDIT_USER_QUESTIONNAIRE_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: EDIT_USER_QUESTIONNAIRE_FAILURE });
      });
  };

  export const newContract = (values, id) => (dispatch, getState) => {
    dispatch({ type: NEW_CONTRACT_USER_REQUEST });
  
    OptionsAuth(getState().token);

    return axios
      .post(`${url}/api/v1/user/newcontract/${id}`, {
     
        values
      })
      .then(({data}) => {
       // console.log( data)
       dispatch({
          type: NEW_CONTRACT_USER_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: NEW_CONTRACT_USER_FAILURE });
      });
  };

  export const newBHP = (values, id) => (dispatch, getState) => {
    dispatch({ type: NEW_BHP_USER_REQUEST });
  
    OptionsAuth(getState().token);

    return axios
      .post(`${url}/api/v1/user/newbhp/${id}`, {
     
        values
      })
      .then(({data}) => {
       // console.log( data)
       dispatch({
          type: NEW_BHP_USER_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: NEW_BHP_USER_FAILURE });
      });
  };

  
  export const newMedical = (values, id) => (dispatch, getState) => {
    dispatch({ type: NEW_MEDICAL_USER_REQUEST });
  
    OptionsAuth(getState().token);

    return axios
      .post(`${url}/api/v1/user/newmedical/${id}`, {
     
        values
      })
      .then(({data}) => {
       // console.log( data)
       dispatch({
          type: NEW_MEDICAL_USER_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: NEW_MEDICAL_USER_FAILURE });
      });
  };

  export const editResponsibility = (values, id) => (dispatch, getState) => {
    dispatch({ type: EDIT_RESPONSIBILITY_USER_REQUEST });
  
    OptionsAuth(getState().token);

    return axios
      .post(`${url}/api/v1/user/editresponsibility/${id}`, {
     
        values
      })
      .then(({data}) => {
       // console.log( data)
       dispatch({
          type: EDIT_RESPONSIBILITY_USER_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: EDIT_RESPONSIBILITY_USER_FAILURE });
      });
  };

  export const removeContract = ( id) => (dispatch, getState) => {
    dispatch({ type: REMOVE_CONTRACT_USER_REQUEST });
  
    OptionsAuth(getState().token);

    return axios
      .get(`${url}/api/v1/user/removecontract/${id}`, {
     
     
      })
      .then(({data}) => {
       // console.log( data)
       dispatch({
          type: REMOVE_CONTRACT_USER_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: REMOVE_CONTRACT_USER_FAILURE });
      });
  };

  export const removeCompanystuff = ( id) => (dispatch, getState) => {
    dispatch({ type: REMOVE_COMPANYSTUFF_USER_REQUEST });
  
    OptionsAuth(getState().token);

    return axios
      .get(`${url}/api/v1/user/removecompanystuff/${id}`, {
     
     
      })
      .then(({data}) => {
       // console.log( data)
       dispatch({
          type: REMOVE_COMPANYSTUFF_USER_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: REMOVE_COMPANYSTUFF_USER_FAILURE });
      });
  };

  export const newCompanystuff = (values, id) => (dispatch, getState) => {
    dispatch({ type: NEW_COMPANYSTUFF_USER_REQUEST });
  
    OptionsAuth(getState().token);

    return axios
      .post(`${url}/api/v1/user/newcompanystuff/${id}`, {
     
        values
      })
      .then(({data}) => {
       // console.log( data)
       dispatch({
          type: NEW_COMPANYSTUFF_USER_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: NEW_COMPANYSTUFF_USER_FAILURE });
      });
  };

  export const newDocument = (values,id) => (dispatch, getState) => {
    dispatch({ type: NEW_DOCUMENT_USER_REQUEST });

    OptionsAuth(getState().token);
  
  const formData = new FormData();
        
  formData.append('avatar',values.file)
      return axios
      .post(`${url}/api/v1/user/newdocument/${id}`, 
     
      formData,
     
      
      )
      .then(({data}) => {
   
    
        dispatch({
           type: NEW_DOCUMENT_USER_SUCCESS,
           payload: {
            
             data,
           },
         });
       })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: NEW_DOCUMENT_USER_FAILURE, error: err });
      });
    
   
    
  };

  export const removeDocument = ( id) => (dispatch, getState) => {
    dispatch({ type: REMOVE_DOCUMENT_USER_REQUEST });
  
    OptionsAuth(getState().token);

    return axios
      .get(`${url}/api/v1/user/removedocument/${id}`, {
     
     
      })
      .then(({data}) => {
        console.log( data)
       dispatch({
          type: REMOVE_DOCUMENT_USER_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: REMOVE_DOCUMENT_USER_FAILURE });
      });
  };

