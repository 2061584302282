import React  from 'react'

import Settings from 'views/Settings';
import Personal from 'components/organisms/EditUsers/Personal/Questionnaire';



const UserQuestionnaire = ({match}) => {

  
    return (
    <Settings>
       
      <Personal id={match.params.id}/>
     

   
    </Settings>
    )


}



export default UserQuestionnaire;