import React  from 'react'

import Settings from 'views/Settings';
import BHPs from 'components/organisms/EditUsers/Personal/BHPs';



const UserBHPs= ({match}) => {

  
    return (
    <Settings>
       
      <BHPs id={match.params.id}/>
     

   
    </Settings>
    )


}



export default UserBHPs;