import axios from 'axios';


export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';

export const ACTIVATION_REQUEST = 'ACTIVATION_REQUEST';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAILURE = 'ACTIVATION_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const AUTHORIZE_REQUEST='AUTHORIZE_REQUEST';
export const AUTHORIZE_SUCCESS='AUTHORIZE_SUCCESS';
export const AUTHORIZE_FAILURE='AUTHORIZE_FAILURE';


export const CLEAR_MESSAGE_SUCCESS= 'CLEAR_MESSAGE_SUCCESS';


export const url='https://kmusial.pl/calendariv';
 


export const OptionsAuth = (apitoken) =>{
  axios.interceptors.request.use(function (config) {
    // assume your access token is stored in local storage 
    // (it should really be somewhere more secure but I digress for simplicity)
    let token = apitoken
    if (token) {
       config.headers['Authorization'] = `Bearer ${token}`
    }
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });
}

export const authenticate = (username, password) => dispatch => {
  dispatch({ type: AUTH_REQUEST });

  return axios
    .post(`${url}/api/v1/login`, {
      username,
      password,
    })
    .then(payload => {
     
      localStorage.setItem('userID', payload.data.user.id);
      localStorage.setItem('userAvatar', payload.data.user.avatar_path);
      localStorage.setItem('userSurname', payload.data.user.name);
      localStorage.setItem('token', payload.data.token.token);
     
      dispatch({ type: AUTH_SUCCESS, payload });
    })
    .catch(err=> {
      console.log(err.response.data);
      dispatch({ type: AUTH_FAILURE, error: err});
    });
};

export const activation = (token) => dispatch => {
  dispatch({ type: ACTIVATION_REQUEST });

  return axios
    .get(`${url}/api/v1/activation/${token}`, {
     
    })
    .then(({data}) => {
   console.log(data)
      
      dispatch({ type: ACTIVATION_SUCCESS, 
         payload: {
           
          data,
        }
     });
    })
    .catch(err=> {
      console.log(err.response.data);
      dispatch({ type: ACTIVATION_FAILURE, error: err});
    });
};

export const register = (values) => (dispatch, getState) => {
  dispatch({ type: REGISTER_REQUEST });

  return axios
    .post(`${url}/api/v1/register`, {
     values
    })
    .then(({data})=> {
 

      
      dispatch({ 
        type: REGISTER_SUCCESS,
          payload: {
            data
          }
       });
    })
    .catch(err=> {
      console.log(err.response.data);
      dispatch({ type: REGISTER_FAILURE, error: err});
    });
};



export const logout = () => (dispatch, getState) => {
  dispatch({ type: AUTH_REQUEST });

  return axios
    .get(`${url}/api/v1/logout`, {
     
    })
    .then(payload => {
      
    
      dispatch({ type: LOGOUT_SUCCESS, payload });
    })
    .catch(err=> {
      console.log(err.response.data);
    
    });
};

export const clearMessage = () => (dispatch) => {
  dispatch({ type: CLEAR_MESSAGE_SUCCESS });

 

};










