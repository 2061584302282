import React  from 'react'
import Settings from 'views/Settings';

import List from 'components/organisms/Company/Invoices/List';



const Invoices =({match}) =>{
        

    
    return (
    <Settings>
       <List  id={match.params.id}/>
    </Settings>
    )


}



export default Invoices;