import React   from 'react'
import styled from 'styled-components';
import Heading from 'components/atoms/Heading/Heading';
import HeaderWrapper from 'components/atoms/HeaderWrapper/HeaderWrapper';
import Card from 'components/molecules/Card/Card';
import ListMenu from 'components/atoms/ListMenu/ListMenu';
import Item from 'components/atoms/ListMenu/Item';
import Button from 'components/atoms/Button/Button';
import { Redirect, NavLink } from 'react-router-dom';


const StyledMenu = styled(Card)`
  height: 100%;
  margin-left:20px;

    ${({ theme }) => theme.mq.standard} {
      margin: 0 0 10px 0;
      height: 100px;
    
    }
    ${({ theme }) => theme.mq.mobile} {
      margin: 0 0 10px 0;
      height: 100%;
    
    }
 
`;

const QuestionnaireMenu = ({id,pageContext,authuserinfo})=>{


    return (
        <StyledMenu pagecolor={pageContext.pageColor}>
        <HeaderWrapper  activeColor={pageContext.sidebarColor}>
        <Heading>Menu</Heading>
        </HeaderWrapper>

        <ListMenu pagecolor={pageContext.pageColor}>
        
        <Item><Button as={NavLink} to={`/settings/users/${id}/questionnaire`} activecolor={pageContext.sidebarColor} small>Kwestionariusz</Button></Item>
        <Item><Button as={NavLink} to={`/settings/users/${id}/contracts`} activecolor={pageContext.sidebarColor} small>Umowy</Button></Item>
        <Item><Button as={NavLink} to={`/settings/users/${id}/bhps`} activecolor={pageContext.sidebarColor} small>BHP</Button></Item>
        <Item><Button as={NavLink} to={`/settings/users/${id}/medicals`} activecolor={pageContext.sidebarColor} small>Badania lekarskie</Button></Item>
        <Item><Button as={NavLink} to={`/settings/users/${id}/responsibility`} activecolor={pageContext.sidebarColor} small>Zakres obowiązków</Button></Item>
        <Item><Button as={NavLink} to={`/settings/users/${id}/documents`} activecolor={pageContext.sidebarColor} small>Dokumenty</Button></Item>
        <Item><Button as={NavLink} to={`/settings/users/${id}/companystuff`} activecolor={pageContext.sidebarColor} small>Rzeczy firmowe</Button></Item>
        

        </ListMenu>
        </StyledMenu>
    )
}


export default QuestionnaireMenu;