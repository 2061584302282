import React  from 'react'

import Settings from 'views/Settings';

import EditUsers from 'components/organisms/EditUsers/EditUsers';



const Users = () => {

 


        


    return (
    
    <Settings>
  
      
        <EditUsers/>
      
    </Settings>
        
    )


}



  
 

export default Users;