import React, {Component}   from 'react'
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { Redirect, NavLink } from 'react-router-dom';
import {routes} from 'routes';
import Paragraph from 'components/atoms/Paragraph/Paragraph';
import Heading from 'components/atoms/Heading/Heading';
import Button from 'components/atoms/Button/Button';
import Input from 'components/atoms/Input/Input';
import Select from 'components/atoms/Input/Select';
import { getCompanyInfo as getCompanyInfoAction } from 'actions/company';

import Card from 'components/molecules/Card/Card';
import HeaderWrapper from 'components/atoms/HeaderWrapper/HeaderWrapper';
import InnerWrapper from 'components/atoms/InnerWrapper/InnerWrapper';
import Buttons from 'components/atoms/Buttons/Buttons';

import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import { editCustomer as editCustomerAction } from 'actions/company';
import {clearMessage as clearMessageAction} from 'actions';
import { connect } from 'react-redux';
import withContext from 'hoc/withContext';
import gsap from "gsap";
import {alerts} from 'helpers/alerts';

import * as Yup from 'yup'; 


const StyledWrapper = styled.div`
  display:flex;
`;




const EditUserSchema = Yup.object().shape({
    

    company: Yup.string()
   
    .required('required'),
  
    email: Yup.string()
      .email('invalidemail')
      .required('required'),
      
  });
  

class EditCustomerForm extends Component {

  constructor(){
    super();
    this.ShowUserInfoWrap = null;
    this.FormUserInfoWrap = null;
  
    this.tlUserInfo = null;
    this.FormUserInfoWrap= null;
   
}

state={
  redirect:null
}

  componentDidMount(){

    const {  id, getCompanyInfo, clearMessage } = this.props;
    getCompanyInfo(id);
    clearMessage();
   
    
   

    this.tlUserInfo = gsap.timeline({defaults:{ease:'power3.inOut'}})
        .set([this.FormUserInfoWrap], {autoAlpha: 0})
       .fromTo(this.FormUserInfoWrap, { y: "-100"}, {duration: 1, y:"+=100", autoAlpha:1})
   
    
    }

    render() {
       
        const { id,companyinfo, pageContext, message, authuserinfo, clearMessage, editCustomer} =this.props;
        const {redirect}=this.state;

        const roleAdmin = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='SuperAdmin').length;
        const roleCustomer = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='Customer').length && companyinfo.id==authuserinfo.company_id;

        const companyemail=companyinfo && companyinfo.users && companyinfo.users[0].email;

      
     
        if(redirect)
        {
          return <Redirect to={redirect} />;
        }

        return (
          

         
            <StyledWrapper ref={div => this.FormUserInfoWrap = div}>
            { roleAdmin || roleCustomer ?
            <Card pagecolor={pageContext.pageColor}>
            <HeaderWrapper  activeColor={pageContext.sidebarColor}>
              <Heading>{pageContext.t('card.companyedit')}: {companyinfo.name} </Heading>
              
             
            </HeaderWrapper>
            <Formik enableReinitialize
            initialValues={{  email: companyemail, company: companyinfo.name, url: companyinfo.url}}
            validationSchema={EditUserSchema}
    
            onSubmit={values => {
              editCustomer(id,values);
              this.setState({ redirect: `/settings/company/${id}` });

             
            }}
      
            >  
            {({ values, handleChange, handleBlur,errors, touched }) => (
              <Form>
              <InnerWrapper  pagecolor={pageContext.pageColor}>
             
              {message!='datahasbeenchanged' ? alerts(pageContext,'card.validate',message,'') : ''}
              
               
    
                <Heading medium>E-mail</Heading>
                <Input card
                type="text"
                name="email"
                placeholder={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                className={`form-control ${
                  errors.email && touched.email ? "is-invalid" : ""
                }`}
                />
                {errors.email && touched.email ? (
                  <ErrorMessage>{pageContext.t(`card.validate.${errors.email}`)}</ErrorMessage>
                ) : null}
                    
                <Heading medium>{pageContext.t('company.name')}</Heading>
                <Input card
                type="text"
                name="company"
                placeholder={values.company}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.company}
                className={`form-control ${
                  errors.company && touched.company ? "is-invalid" : ""
                }`}
                />
                {errors.company && touched.company ? (
                  <ErrorMessage>{pageContext.t(`card.validate.${errors.company}`)}</ErrorMessage>
                ) : null}

                <Heading medium>{pageContext.t('company.url')}</Heading>
                <Input card
                type="text"
                name="url"
                placeholder={values.url}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.url}
                className={`form-control ${
                  errors.url && touched.url ? "is-invalid" : ""
                }`}
                />
              

              </InnerWrapper>
              
              <Buttons pagecolor={pageContext.pageColor} activeColor={pageContext.sidebarColor}>
              
                <Button type="button"
                as={NavLink} to={`/settings/company/${id}`} medium back >
                {pageContext.t('button.back')}
                </Button>
    
                <Button type="submit" medium mediumaccept activecolor={pageContext.sidebarColor} >
                {pageContext.t('button.save')}
                </Button>
              </Buttons>
              </Form>
            )}
            </Formik>
          </Card>
          :   ''}
          </StyledWrapper>
      
         
        )
    }
}

const mapStateToProps = state => {

  const { message, authuserinfo,companyinfo } = state;

  return { message,  authuserinfo, companyinfo};
};

const mapDispatchToProps = dispatch => ({
  editCustomer: (id,values) => dispatch(editCustomerAction(id,values)),
  getCompanyInfo: (id) =>dispatch(getCompanyInfoAction(id)),
  clearMessage: () =>dispatch(clearMessageAction())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withContext(EditCustomerForm));