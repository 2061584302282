import React, {Component}   from 'react'
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { Redirect, NavLink } from 'react-router-dom';
import {routes} from 'routes';
import Hr from 'components/atoms/Hr/Hr';
import SmallSpinner from 'components/atoms/Spinner/SmallSpinner';
import correctIcon from 'assets/icons/correct.svg';

import Paragraph from 'components/atoms/Paragraph/Paragraph';
import Heading from 'components/atoms/Heading/Heading';
import Button from 'components/atoms/Button/Button';
import Input from 'components/atoms/Input/Input';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import ListMenu from 'components/atoms/ListMenu/ListMenu';
import Item from 'components/atoms/ListMenu/Item';
import Image from 'components/atoms/Image/Image';

import { fetchInvoices as fetchInvoicesAction} from 'actions/subscription';
import { getCompanyInfo as getCompanyInfoAction } from 'actions/company';

import {clearMessage as clearMessageAction} from 'actions';
import { connect } from 'react-redux';
import withContext from 'hoc/withContext';
import gsap from "gsap";
import {alerts} from 'helpers/alerts';
import DefaultTable from 'components/molecules/Table/DefaultTable';
import moment from 'moment';

import Card from 'components/molecules/Card/Card';
import HeaderWrapper from 'components/atoms/HeaderWrapper/HeaderWrapper';
import InnerWrapper from 'components/atoms/InnerWrapper/InnerWrapper';
import Buttons from 'components/atoms/Buttons/Buttons';
import ButtonsInTable from 'components/atoms/Buttons/ButtonsInTable';

const StyledWrapper = styled.div`
  display:flex;
  flex-direction:row;
  ${({ theme }) => theme.mq.standard} {
   
   flex-direction:column-reverse;
   max-width:700px;
 }

 ${({ theme }) => theme.mq.tablet} {
   max-width:700px;
 }
 ${({ theme }) => theme.mq.mobile} {
   max-width:300px;
 }
`;



const StyledInnerWrapper= styled(InnerWrapper)`
   
    align-items:center;
    max-height:100%;
    
`;









class List extends Component {
    constructor(){
        super();
        this.ShowUserInfoWrap = null;
        this.FormAvatarWrap = null;
      
        this.tlUserInfo = null;
        this.tlAvatarForm = null;
        
        this.toggleAvatarForm = this.toggleSubscriptionDetails.bind(this);
       
       
    }
  
   

    componentDidMount(){

        const { id, getCompanyInfo ,fetchInvoices} = this.props;
       
        getCompanyInfo(id);
        fetchInvoices(id);
        //clearMessage();
       
    
        this.tlUserInfo = gsap.timeline({defaults:{ease:'power3.inOut'}})
        .set([this.ShowUserInfoWrap,  this.FormAvatarWrap], {autoAlpha: 0})
        .fromTo(this.ShowUserInfoWrap, { y: "-100"}, {delay:1,duration: 1, y:"+=100", autoAlpha:1})
       
        
    }

    toggleSubscriptionDetails(title,price){
    
      this.setState({
        title,price
      });
      
      this.tlAvatarForm = gsap.timeline({defaults:{ease:'power3.inOut'}})
     
      .fromTo(this.FormAvatarWrap, { y: "-100px"}, {duration: 0.5, y:"+=100px", autoAlpha:1})
      
    }

   

   

    render() {
        
      const {id, invoices, pageContext,authuserinfo, message, events, clearMessage, companyinfo, isLoadingContent, alert} =this.props;

   
    
      const roleAdmin = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='SuperAdmin').length;
      const roleCustomer = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='Customer').length && companyinfo.id==authuserinfo.company_id;
    
     

    const headers=[];
    headers.push(pageContext.t('name'),pageContext.t('statistics.expired'), pageContext.t('statistics.renewal'),pageContext.t('statistics.payment_method'),"Status",pageContext.t('table.action'))

    const body=[];
 
    invoices && invoices.map(invoice=>{
      const dateend=invoice.name.name!='Full' ? `${moment(invoice.end).format("DD-MM-YYYY H:mm")}` : '';
      const dateendexpired=invoice && invoice.name.name!='Full' ? `${moment(invoice && invoice.renew_date).format("DD-MM-YYYY H:mm")}` : '';

        const payment_name=invoice && invoice.payment[0] && invoice.payment[0].payment_name;
       const subname=invoice && invoice.subscription &&  (invoice.name.name!='Full' && invoice.name.name!='Trial') ? invoice.subscription.name : " ";
        const renew=invoice && invoice.payment[0] && invoice.payment[0].renew;

        body.push({name:invoice.name.name+" "+ subname ,date:invoice.name.name!='Full' ? dateend+" UTC+01:00" : "", dateexpired: dateendexpired  && renew==1 ? dateendexpired+" UTC+01:00" : "" + invoice.name.name!='Full' || invoice.name.name!='Trial' ? "" : pageContext.t(`payment.canceled`)  ,payment:payment_name , status:pageContext.t(`payment.${invoice.status}`), button:<ButtonsInTable><Button as={NavLink} to={`/settings/company/${id}/invoices/${invoice.id}`} activecolor={pageContext.sidebarColor} small>{pageContext.t('button.details')}</Button>  </ButtonsInTable> })
      });

      const now=moment().format("YYYY-MM-DD H:mm:ss");
      const accesscompany=companyinfo && companyinfo.access && companyinfo.access.length && companyinfo.access[0].name.name;
      const accesscompanyenddate=companyinfo && companyinfo.access && companyinfo.access.length && companyinfo.access[0].end;
      const renew=companyinfo && companyinfo.access && companyinfo.access.length && companyinfo.access[0].payment && companyinfo.access[0].payment.length 
      && companyinfo.access[0].payment[0].renew;
      const expiredcompany=accesscompany!='Full' && accesscompanyenddate< now ;
      const expirednotification=accesscompanyenddate >now &&  moment(accesscompanyenddate).diff(now, 'days')<7;

        return (
        <StyledWrapper >
      
            <Card ref={div => this.ShowUserInfoWrap = div} pagecolor={pageContext.pageColor}>
            { roleAdmin || roleCustomer ?
          <>
                <HeaderWrapper  activeColor={pageContext.sidebarColor}>
                    <Heading>{pageContext.t('card.invoiceslist')}: {companyinfo.name}</Heading>
                    
                
                </HeaderWrapper>
                <StyledInnerWrapper  pagecolor={pageContext.pageColor}>
              {expirednotification && !renew ? alerts(pageContext,'alerts.','subscriptionends',''): ''}
                {expiredcompany && !renew  ? alerts(pageContext,'alerts.','subscriptionexpired','') : ''}
                {!isLoadingContent ?
                <DefaultTable  headers={headers} body={body}/>
                : <SmallSpinner activecolor={pageContext.sidebarColor} className="loader" />}
        
                </StyledInnerWrapper>
                <Buttons pagecolor={pageContext.pageColor} activeColor={pageContext.sidebarColor}>
              
                    <Button type="button"
                    as={NavLink} to={`/settings/company/${id}`} medium back >
                    {pageContext.t('button.back')}
                    </Button>

                  { accesscompany!='Full' && renew==0 ?  <Button type="button"
                    as={NavLink} to={`/settings/company/${id}/subscriptions`} medium activecolor={pageContext.sidebarColor} >
                  {pageContext.t('button.buysubscription')}
                    </Button> : ""}
                </Buttons>
                </>
            : ''}
            </Card>

         

          
           
        
        </StyledWrapper>
        )
    }
  
}
    

const mapStateToProps = state => {

    const { alert, message, invoices, events, authuserinfo, companyinfo, isLoadingContent} = state;
  
    return { alert, message, invoices,events, authuserinfo, companyinfo, isLoadingContent};
  };
  
  const mapDispatchToProps = dispatch => ({
   
    getCompanyInfo: (id) =>dispatch(getCompanyInfoAction(id)),
    fetchInvoices: (id) =>dispatch(fetchInvoicesAction(id)),
    
    
    clearMessage: () =>dispatch(clearMessageAction())
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withContext(List));