import React from 'react';
import styled from 'styled-components';

 const Label=styled.label`

font-size:1rem;
margin-top:-10px;
text-align:center;
`;

export default(Label)