import React, {Component}   from 'react'
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';

import { Redirect, NavLink } from 'react-router-dom';
import {routes} from 'routes';
import SmallSpinner from 'components/atoms/Spinner/SmallSpinner';
import Modal from 'components/molecules/Modal/Modal';

import Paragraph from 'components/atoms/Paragraph/Paragraph';
import Heading from 'components/atoms/Heading/Heading';
import Button from 'components/atoms/Button/Button';
import Card from 'components/molecules/Card/Card';
import HeaderWrapper from 'components/atoms/HeaderWrapper/HeaderWrapper';
import InnerWrapper from 'components/atoms/InnerWrapper/InnerWrapper';
import Buttons from 'components/atoms/Buttons/Buttons';

import moment from 'moment';

import { checkOrderStatus as checkOrderStatusAction, cancelOrder as  cancelOrderAction} from 'actions/subscription';


import { getCompanyInfo as getCompanyInfoAction } from 'actions/company';

import {clearMessage as clearMessageAction} from 'actions';
import { connect } from 'react-redux';
import withContext from 'hoc/withContext';
import gsap from "gsap";
import {alerts} from 'helpers/alerts';


const StyledWrapper = styled.div`
  display:flex;
 
  justify-content: flex-start;
  ${({ theme }) => theme.mq.standard} {
   
    flex-direction:column-reverse;
    max-width:500px;
  }

  ${({ theme }) => theme.mq.tablet} {
    max-width:400px;
  }
  ${({ theme }) => theme.mq.mobile} {
    max-width:300px;
  }
  
`;



const StyledInnerWrapper= styled(InnerWrapper)`
   
    flex-direction: column;
    justify-content: start;
  
`;
const InfoContent= styled.div`
    display: flex;
    flex-direction:column;
    padding:10px;
`;






class Info extends Component {

  constructor(){
    super();
   
    this.tlUserInfo = null;

    this.FormUserInfoWrap= null;
    
}

  state={
    redirect:null,
    modalVisible:false,
    isAccept: false,
  
    isDelete: false,
    messageModal:''
  }
  componentDidUpdate(prevProps, prevState) {

    const { id,payment, cancelOrder,getCompanyInfo } = this.props;


    if (id !== prevProps.id) {
      getCompanyInfo(id);
    }

 

  if(this.state.isAccept && this.state.isDelete && this.state.isAccept!=prevProps.isAccept)
  {
   
    cancelOrder(payment);
    this.hideModal();

    this.setState ({
      isAccept: false,
    });
  }

  }

  componentDidMount(){

    const { id,payment,  clearMessage, getCompanyInfo, checkOrderStatus } = this.props;

    getCompanyInfo(id);
    checkOrderStatus(payment);
    clearMessage();
   

    this.tlUserInfo = gsap.timeline({defaults:{ease:'power3.inOut'}})
        .set([this.FormUserInfoWrap,this.UserMenuWrap], {autoAlpha: 0})
        .fromTo(this.FormUserInfoWrap, { y: "-100"}, { delay:1,duration: 1, y:"+=100", autoAlpha:1})
       
    
  }

  showModal = (text, type) =>{


    this.setState ({
      messageModal: text,
      modalVisible: true,
    
      isDelete: type=='isDelete' ? true : false
    });
    
  
  }

  hideModal= () =>{
    this.setState ({
    modalVisible: false,
    });
  }

  acceptedAction = () =>{
    this.setState ({
      isAccept: true,
    });
  }

    render() {

        const {redirect, modalVisible, isAccept, messageModal}=this.state;

        const {id, accessItem, authuserinfo, companyinfo, pageContext, alert, message, clearMessage, isLoadingContent} =this.props;
     
         const roleAdmin = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='SuperAdmin').length;
        const roleCustomer = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='Customer').length && companyinfo.id==authuserinfo.company_id;

        const info=accessItem && accessItem[0];
        
        const version=info && info.name.name;
       // const datestart=`${moment(info && info.start).format("DD-MM-YYYY H:mm")}`;
        const dateendexpired=info && info.name.name!='Full' ? `${moment(info && info.renew_date).format("DD-MM-YYYY H:mm")}` : '';
        const dateend=info && info.name.name!='Full' ? `${moment(info && info.end).format("DD-MM-YYYY H:mm")}` : '';
        const status=info && info.status;
        const payment_name=info && info.payment[0] && info.payment[0].payment_name;
        const renew=info && info.payment[0] && info.payment[0].renew;
        const price=info && info.payment[0] && info.payment[0].price;
        const type=info && info.payment[0] && info.payment[0].type;
        const subname=info && info.subscription &&  (info.name.name!='Full' && info.name.name!='Trial') ? info.subscription.name : " ";
       
        return (
       
          <>
          <Modal hideModal={this.hideModal} text={messageModal}  error={alert} acceptedAction={this.acceptedAction} isAccept={isAccept}  isVisible={modalVisible}/>
          
          <StyledWrapper ref={div => this.FormUserInfoWrap = div}>
           
          { roleAdmin || roleCustomer  ?
            <>
            <Card pagecolor={pageContext.pageColor}>
              <HeaderWrapper  activeColor={pageContext.sidebarColor}>
                <Heading>{pageContext.t(`card.order`)} nr. {info && info.id}</Heading>
              
             
              </HeaderWrapper>
              <StyledInnerWrapper pagecolor={pageContext.pageColor}>
              {alert  ? alerts(pageContext,'alerts.',message,'success') : ""}
              {!isLoadingContent ?
               <>
                  
                  <InfoContent>
                 

                    <Heading medium>{pageContext.t(`card.version`)}</Heading>
                    <Paragraph medium>{version} {subname} </Paragraph>

                    

                    {version!='Trial' && version!='Full' ?
                    <>
                     <Heading medium>{pageContext.t(`card.automatic_renewal`)}</Heading>
                    <Paragraph medium>{dateendexpired  && renew ? dateendexpired+" UTC+01:00" : pageContext.t(`payment.canceled`)} </Paragraph>
                    </>
                    :""
                    }
                    <Heading medium>{pageContext.t('statistics.expired')}</Heading>
                    <Paragraph medium>{dateend} UTC+01:00</Paragraph>

                    {version!='Trial' && version!='Full' ?
                    <>
                    <Heading medium>{pageContext.t('statistics.payment_method')}</Heading>
                    <Paragraph medium>{payment_name}</Paragraph>
                   

                    <Heading medium>Status</Heading>
                    <Paragraph medium>{pageContext.t(`payment.${status}`)}</Paragraph>

                    <Heading medium>{pageContext.t('price')}</Heading>
                    <Paragraph medium>{price} PLN</Paragraph>

                    <Heading medium>{pageContext.t('recurring_payment')}</Heading>
                    <Paragraph medium>{pageContext.t(`payment.${type}`)}</Paragraph>
                    </>
                    :""
                    }
                    
                  </InfoContent>
                </>
                :<SmallSpinner activecolor={pageContext.sidebarColor} className="loader" />}
              </StyledInnerWrapper>
              
              <Buttons pagecolor={pageContext.pageColor} activeColor={pageContext.sidebarColor}>
              {!isLoadingContent ? 
                <>

                    <Button type="button"
                    as={NavLink} to={`/settings/company/${id}/invoices`} medium back >
                    {pageContext.t('button.back')}
                    </Button>

                    {status=='COMPLETED' && renew==1 ? 
                    <Button type="button" onClick={()=>this.showModal(`${pageContext.t(`alerts.cancel_subscription.first`)} (${dateendexpired} UTC+01:00) ${pageContext.t(`alerts.cancel_subscription.second`)}`, 'isDelete')}
                    medium activecolor={pageContext.sidebarColor} >
                   {pageContext.t(`button.cancel_subscription`)}
                    </Button> 
                    : ''}
                 
             

              </>
              : ''}
             
            
              </Buttons>
             
            </Card>

          
            </>
            : ''}
          </StyledWrapper>
          </>
         
        )
    }
}

const mapStateToProps = (state, ownProps) => {

  const { alert, message,  authuserinfo, companyinfo, isLoadingContent} = state;

  return { 
      accessItem: companyinfo && companyinfo.access && companyinfo.access.length && companyinfo.access.filter(item=>item.id==ownProps.payment && item.company_id==ownProps.id),
      message,  
      authuserinfo, 
      companyinfo, 
      isLoadingContent,
      alert
    };
};

const mapDispatchToProps = dispatch => ({
  getCompanyInfo: (id) =>dispatch(getCompanyInfoAction(id)),
  cancelOrder: (id) =>dispatch(cancelOrderAction(id)),

  checkOrderStatus: (id) =>dispatch(checkOrderStatusAction(id)),
  clearMessage: () =>dispatch(clearMessageAction())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withContext(Info)); 