
import {url, OptionsAuth} from 'actions'
import axios from 'axios';



export function Payu(payuSdkForms,id,price,type,lang){
   
    var renderError = function(element, errors) {
        element.className = 'response-error';
        var messages = [];
        errors.forEach(function(error) {
            messages.push(error.message);
        });
        element.innerText = messages.join(', ');
    };
    
    var renderSuccess = function( msg,id,price,type,lang) {
        
       
    
        OptionsAuth(localStorage.getItem('token'));
             axios
              .post(`${url}/api/v1/subscription/payu/new_order`, {
             tok:msg,
             id:id,
             price:price,
             type:type,
             lang:lang
              })
              .then(({data}) => {
        
            //console.log(data)
            // Simulate an HTTP redirect:
               window.location.replace(data.data);
              })
              .catch(err => {
               console.log(err.response);
               
              });
         
    
    }
    
    

    var tokenizeButton = document.getElementById('tokenizeButton');
    var responseElement = document.getElementById('responseTokenize');
    
    
        responseElement.innerText = '';
    
        try {
            //tokenizacja karty (komunikacja z serwerem PayU)
            payuSdkForms.tokenize('MULTI').then(function(result) {
              
                result.status === 'SUCCESS'
                    ? renderSuccess(result.body.token,id,price,type,lang) //tutaj wstaw przekazanie tokena do back-endu
                    : renderError(responseElement, result.error.messages); //sprawdź typ błędu oraz komunikaty i wyświetl odpowiednią informację użytkownikowi
            });
        } catch(e) {
            console.log(e); // błędy techniczne
        }
 
}
