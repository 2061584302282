import React  from 'react'
import Settings from 'views/Settings';

import EventsList from 'components/organisms/Company/Events/EventsList';



const Events =({match}) =>{
        

    
    return (
    <Settings>
       <EventsList  id={match.params.id}/>
    </Settings>
    )


}



export default Events;