import React  from 'react'
import Settings from 'views/Settings';

import EventCreateForm from 'components/organisms/Company/Events/CreateForm';



const Create =({match}) =>{
        

      
    return (
    <Settings>
       <EventCreateForm  id={match.params.id}/>
    </Settings> 
    )


}



export default Create;