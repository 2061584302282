import React, { Component }  from 'react'
import styled from 'styled-components';

import CalendarUserTemplate from 'templates/CalendarUserTemplate';
import Heading from 'components/atoms/Heading/Heading';
import SmallSpinner from 'components/atoms/Spinner/SmallSpinner';
import Pdf from "react-to-pdf";
import StatisticsTable from 'components/molecules/Table/StatisticsTable';
import EventsTable from 'components/molecules/EventsTable/EventsTable';
import Paragraph from 'components/atoms/Paragraph/Paragraph';
import Card from 'components/molecules/Card/Card';
import HeaderWrapper from 'components/atoms/HeaderWrapper/HeaderWrapper';
import InnerWrapper from 'components/atoms/InnerWrapper/InnerWrapper';
import DropdownButton from 'components/atoms/DropdownButton/DropdownButton';
import PropTypes from 'prop-types';
import ListMenu from 'components/atoms/ListMenu/ListMenu';
import Buttons from 'components/atoms/Buttons/Buttons';
import { Redirect, NavLink } from 'react-router-dom';
import Button from 'components/atoms/Button/Button';
import { StatusText, DateFormat,  getElapsedWorkDays} from 'helpers/events';
import withContext from 'hoc/withContext';
import {  fetchEvents ,fetchEventsUserFromCalendar as  fetchEventsUserFromCalendarAction} from 'actions/event';
import { getUserInfo as getUserInfoAction } from 'actions/user';


import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import gsap from "gsap";

import "react-datepicker/dist/react-datepicker.css";
import pl from 'react-datepicker/node_modules/date-fns/locale/pl';
import en from 'react-datepicker/node_modules/date-fns/locale/en-GB';


registerLocale('pl', pl);
registerLocale('en', en);

const InfoContent= styled.div`
    display: flex;
    flex-direction:column;
    padding:10px;
`;

const StyledWrapper = styled.div`
  display:flex;
  
  padding-top:20px;
  max-height: 100vh;

    ${({ theme }) => theme.mq.tablet} {
        padding-left:10%;
        margin-top:40px;
        max-width:700px;
    }
    ${({ theme }) => theme.mq.mobile} {
       
           padding-left:10%;
           padding-right:5%;
           max-width:300px;
    }

    ${({ theme }) => theme.mq.standard} {
    
    flex-direction:column-reverse;
    max-width:700px;
    }


 
`;

const StyledInnerWrapper= styled(InnerWrapper)`
   
   
    max-height:100%;
    
`;

const StyledMenu = styled(Card)`
  height: 100%;
  margin-left:20px;
    justify-content:flex-start;
    ${({ theme }) => theme.mq.standard} {
      margin: 0 0 10px 0;
      height: 100px;
    
    }
    ${({ theme }) => theme.mq.mobile} {
      margin: 0 0 10px 0;
      height: 100%;
    
    }
 
`;



const StyledSelectDates=styled.div`
  display:flex;
  padding:10px;
  flex-wrap:wrap;
`;

const StyledButtons=styled.div`
  display:flex;
  padding-top:5px;
`;
const StyledDropdownButton=styled.div`
  
     margin:6px 16px 6px 6px;
   
`;

const StyledDates=styled.div`
  display:flex;
  min-width: 100px;
 
`;

const StyledParagraph=styled(Paragraph)`
  margin-top:12px;

`;

class Statistics extends Component {

 
  constructor(){
    super();
    this.ShowUserInfoWrap = null;
    this.FormUserInfoWrap = null;
  
    this.tlUserInfo = null;
    this.tlUserInfoForm = null;
    

    this.toggleUserInfoForm = this.toggleUserInfo.bind(this);
   
}

    state = {
      calendarEvents:[],
      sumofhours:0,
      filter:[],
      startItem:moment(new Date()).startOf('month'),
      endItem:moment(new Date()).endOf('month'),
    };

   

    filtercalendarEvents=()=>{
      const {filter}=this.state;
      const {getuserinfo}=this.props;
   //   console.log(getuserinfo)
      const newEventsfromcalendar=[];
      this.setState({
        sumofhours:this.state.sumofhours=0
      });
 
      const eventsfromcalendarfilter=this.props.eventsfromcalendar.filter(function(item) {
        return filter.includes(item.event_name); 
      })

    
   
      eventsfromcalendarfilter.filter(
    
        event=>
     
        (moment(DateFormat(event.start,'start',event.allDay))>=this.state.startItem && moment(DateFormat(event.start,'start',event.allDay))<=this.state.endItem) && (event.status==2) ||
        (moment(DateFormat(event.end,'end',event.allDay))>=this.state.startItem && moment(DateFormat(event.end,'end',event.allDay))<=this.state.endItem) && (event.status==2)
      
        ).map(event=>{
          let datestart=DateFormat(event.start,'start',event.allDay);
          let dateend=DateFormat(event.end,'end',event.allDay);
          let diff='';

          let weekdays=0;
       
          if(getuserinfo && getuserinfo.works_on_saturdays==0 && getuserinfo.works_on_sundays==0)
           {
            weekdays=getElapsedWorkDays(datestart, dateend,'sundayandsaturday');
        
          }
          else if(getuserinfo && getuserinfo.works_on_saturdays==1 && getuserinfo.works_on_sundays==0)
          {
            weekdays=getElapsedWorkDays(datestart, dateend,'sunday');
          
          }
          else if(getuserinfo && getuserinfo.works_on_saturdays==0 && getuserinfo.works_on_sundays==1)
          {
            weekdays=getElapsedWorkDays(datestart, dateend,'saturday');
         
          }
         

          if(moment(DateFormat(datestart,'start',event.allDay))<this.state.startItem)
          {
            datestart=DateFormat(this.state.startItem,'start',event.allDay);
          }

          if(moment(DateFormat(dateend,'start',event.allDay))>this.state.endItem)
          {
            dateend=DateFormat(this.state.endItem,'start',event.allDay);
          }

          if(event.allDay)
          {
            diff=moment(dateend).diff(datestart, 'days');
            diff=(diff+1-weekdays)*8;
          }
          else{
            diff=moment(event.end).diff(event.start, 'hours','minutes');
            weekdays==0 ? diff=diff : diff=0;
          }

          this.setState({
            sumofhours:this.state.sumofhours+=diff
          });
      
          const hour=Math.floor(diff);
          const minutes=(diff-hour)*60;
          const string=minutes? ':'+minutes : '';

          newEventsfromcalendar.push({
         
          'date':datestart+' - '+dateend,
            
          'counthours':hour+string,
          'category':event.event_name
          })
      });
    
      this.setState({
        calendarEvents:[
         
            ...newEventsfromcalendar
          ]
      });

    
    }

    componentDidUpdate(prevProps, prevState) {
    
      
      if (this.props.eventsfromcalendar !== prevProps.eventsfromcalendar) {

        this.filtercalendarEvents()
      
      }

      if (this.state.startItem !== prevState.startItem || this.state.endItem !== prevState.endItem ) {
      
        this.filtercalendarEvents()
      
      }

      if (this.props.events !== prevProps.events) 
      {
        const newEventsFilter=[];
        this.props.events.map(event=>{
        
          newEventsFilter.push(
          event.name
          )
        });

        this.setState({
          filter:[
            ...this.state.filter,
              ...newEventsFilter
            ]
        });
      }

      if (this.state.filter !== prevState.filter) {
     
        this.filtercalendarEvents()
      
      }



    }
  
    componentDidMount() {
  
      const {id,getUserInfo, fetchEventsUserFromCalendar, fetchEvents} = this.props;
      getUserInfo(id);
      fetchEvents();

      fetchEventsUserFromCalendar(id);

     


      this.tlUserInfo = gsap.timeline({defaults:{ease:'power3.inOut'}})
    .set([this.ShowUserInfoWrap,this.FormUserInfoWrap, this.FormChangePasswordWrap], {autoAlpha: 0})
    .fromTo(this.ShowUserInfoWrap, { y: "-100"}, {duration: 1, y:"+=100", autoAlpha:1})


      
    }

    toggleUserInfo(){

      this.tlUserInfo.to(this.ShowUserInfoWrap, {duration: 0.4,  autoAlpha:0});
      this.tlUserInfoForm = gsap.timeline({defaults:{ease:'power3.inOut'}})
     
      .fromTo(this.FormUserInfoWrap, { y: "-100"}, {duration: 1, y:"+=100", autoAlpha:1})
    }

    setStartDate = (date) =>{
      this.setState({ 
        startItem:date
      })
    }
  
    setEndDate = (date) =>{
      this.setState({ 
        endItem:date
      })
    }


    addToFilter = (data) =>{
     
      const list=this.state.filter;

        if(list.filter(element=>element===data).length > 0)
        {
          
          const newlist=list.filter(element=>element!==data)
          this.setState({ 
            filter:[
             
              ...newlist
            ]
          })
          
      
        }
        else{
          
          this.setState({ 
            filter:[
              ...list, 
              data
            ]
          })
          
        }
    }

    render() {
  

    const {calendarEvents,filter,startItem,endItem,sumofhours} = this.state;
    const {id,getuserinfo,isLoadingContent,pageContext,events,authuserinfo,companyinfo } = this.props;


    const roleAdmin = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='SuperAdmin').length;
    const roleCustomer = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='Customer').length && companyinfo.id==authuserinfo.company_id;

    const headers=[];
    headers.push(pageContext.t('statistics.date'),pageContext.t('counthours'),pageContext.t('statistics.category'))

    const hour=Math.floor(sumofhours);
    const minutes=(sumofhours-hour)*60;
    const string=minutes? ` ${pageContext.t('and')} ${minutes} ${pageContext.t('minutes')}` : '';

    const ref = React.createRef();

    return (
    <CalendarUserTemplate >

      <StyledWrapper ref={div => this.ShowUserInfoWrap = div}>
     
    {roleAdmin || roleCustomer ?
      <>
      <Card pagecolor={pageContext.pageColor}>
          <HeaderWrapper  activeColor={pageContext.sidebarColor}>
            <Heading>{pageContext.t('card.statistics')} {pageContext.t('for')} {getuserinfo.name}</Heading>
                    
                
          </HeaderWrapper>
          <StyledInnerWrapper  >
          {!isLoadingContent ? 
          <>
          <StyledSelectDates>
            <StyledDates >
        
          
            <DatePicker
            selected={Date.parse(moment(startItem))}
            onChange={date =>this.setStartDate(date)}
            
            locale={pageContext.t('lang')}
            dateFormat="dd-MM-yyyy"
            /> 
            <StyledParagraph>-</StyledParagraph>
            <DatePicker
            selected={Date.parse(moment(endItem))}
            onChange={date =>this.setEndDate(date)}
            
            locale={pageContext.t('lang')}
            dateFormat="dd-MM-yyyy"
            />
            
          </StyledDates>

            <StyledButtons>
              <StyledDropdownButton>
                <DropdownButton setStartDate={this.setStartDate} setEndDate={this.setEndDate} pagecolor={pageContext.pageColor}/>
              </StyledDropdownButton>

            </StyledButtons>

          </StyledSelectDates>
          

      <div ref={ref}>
      
        <StatisticsTable  filter={filter} headers={headers} body={calendarEvents} />
        <InfoContent>
          <Heading medium>{pageContext.t('sum')}</Heading>
            <Paragraph medium>{hour+` ${pageContext.t('hours')}`+string} </Paragraph>
          <Heading medium>{pageContext.t('statistics.daterange')}</Heading>
            <Paragraph  medium>{moment(startItem).format("DD-MM-YYYY")} -  {moment(endItem).format("DD-MM-YYYY")}</Paragraph>
          <Heading medium>{pageContext.t('employee')}</Heading>
            <Paragraph  medium>{getuserinfo.name}</Paragraph>
         </InfoContent>
         </div>
        </>
      : <SmallSpinner activecolor={pageContext.sidebarColor} className="loader" />}

      </StyledInnerWrapper>
         <Buttons pagecolor={pageContext.pageColor} activeColor={pageContext.sidebarColor}>
              
              <Button type="button"
              as={NavLink} to={`/settings/users/${id}`} medium back >
              {pageContext.t('button.back')}
              </Button>
              
              <Pdf targetRef={ref} scale={0.8} x={10} y={10} filename="calendar.pdf">
                  {({ toPdf }) => 
                  <Button type="button" onClick={toPdf} medium activecolor={pageContext.sidebarColor} >{pageContext.t('button.print')}</Button>}
              </Pdf>

        </Buttons>
      </Card>

      <StyledMenu pagecolor={pageContext.pageColor} >
              <HeaderWrapper  activeColor={pageContext.sidebarColor}>
                <Heading>Menu</Heading>
              </HeaderWrapper>

              <ListMenu pagecolor={pageContext.pageColor}>
                <EventsTable filter={filter} addToFilter={this.addToFilter} events={events}/>
              </ListMenu>
      </StyledMenu>
      </>
      : ''}
      
      </StyledWrapper>
    
    </CalendarUserTemplate>
    )
  }

}

Statistics.propTypes = {
 
 
  pageColor: PropTypes.oneOf(['sun', 'moon']),
  eventsfromcalendar: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      status: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      
      start: PropTypes.string.isRequired,
      end: PropTypes.string.isRequired,
      allDay: PropTypes.number.isRequired,
      surname: PropTypes.string.isRequired,
      avatar: PropTypes.string.isRequired,
      userid: PropTypes.number.isRequired,
    }),
  ),
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      mark: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    
    }),
  ),
 
};

Statistics.defaultProps = {
  pageColor: 'sun',
  events:[],
  eventsfromcalendar:[],

};

const mapStateToProps = state => {
  const { events,eventsfromcalendar, isLoadingContent,getuserinfo,authuserinfo,companyinfo} = state;
 
  return { events,eventsfromcalendar, isLoadingContent,getuserinfo,authuserinfo,companyinfo };
};

const mapDispatchToProps = dispatch => ({
  fetchEvents: () => dispatch(fetchEvents()),
  fetchEventsUserFromCalendar: (id) => dispatch(fetchEventsUserFromCalendarAction(id)),
  getUserInfo: (id) =>dispatch(getUserInfoAction(id)),

});

export default withContext(connect(mapStateToProps,mapDispatchToProps)(Statistics));