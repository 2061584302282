
import React from 'react';
import styled from 'styled-components';

const Inputbox=styled.div`
  display:flex;
  flex-direction:column;
  max-width:80px;
  margin-right:10px;
`;

export default(Inputbox)