import React  from 'react'

import Settings from 'views/Settings';
import Companystuff from 'components/organisms/EditUsers/Personal/Companystuff';



const UserCompanystuff= ({match}) => {

  
    return (
    <Settings>
       
      <Companystuff id={match.params.id}/>
     

   
    </Settings>
    )


}



export default UserCompanystuff;