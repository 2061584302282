import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'theme/GlobalStyle';
import PageContext from 'context';
import { theme } from 'theme/mainTheme';
import {
  setTranslations,
  setDefaultLanguage,
  translate,
} from 'react-switch-lang';
import en from 'lang/en.json';
import pl from 'lang/pl.json';
import { connect } from 'react-redux';
import {  getAuthUserInfo as getAuthUserInfoAction, disableActiveUsers as disableActiveUsersAction } from 'actions/user';
import {  checkPayments as checkPaymentsAction } from 'actions/subscription';
import moment from 'moment';
import { Redirect, NavLink } from 'react-router-dom';


setTranslations({ en, pl });
setDefaultLanguage(localStorage.getItem('langType') ? localStorage.getItem('langType') : 'pl');

class MainTemplate extends Component {
  state = {
    pageColor: localStorage.getItem('pageColor') ? localStorage.getItem('pageColor') : 'sun',
 
    
  };

  componentDidUpdate(prevProps)
  {
    const {    checkPayments, companyinfo, disableActiveUsers} = this.props;
    const accesscompany=companyinfo && companyinfo.access && companyinfo.access.length && companyinfo.access[0].name.name;
    const accesscompanyenddate=companyinfo && companyinfo.access && companyinfo.access.length && companyinfo.access[0].renew_date;
    const enddate=accesscompanyenddate ? moment(accesscompanyenddate).format("YYYY-MM-DD H:mm:ss") : '';
    const renew=companyinfo && companyinfo.access && companyinfo.access.length && companyinfo.access[0].payment && companyinfo.access[0].payment.length 
    && companyinfo.access[0].payment[0].renew;
    const expiredsubscription=accesscompany!='Full' && enddate< moment().format("YYYY-MM-DD H:mm:ss") && renew;

    const accessusers=companyinfo && companyinfo.access && companyinfo.access.length && companyinfo.access[0] && companyinfo.access[0].subscription && companyinfo.access[0].subscription.number_of_users;

    if(prevProps.companyinfo!=this.props.companyinfo)
    {
         

          if(expiredsubscription)
          {
           // console.log(expiredsubscription)
            checkPayments();
            
          }
          if(accessusers )
          {
             // console.log(accessusers)
            if(this.props.countusers>accessusers)
           {
          
            disableActiveUsers(this.props.countusers,accessusers);
           }
          
          }
          
    }
  
   
  
   
  }

  componentDidMount(){

    const {   getAuthUserInfo, checkPayments, companyinfo} = this.props;
  
    

    getAuthUserInfo();
    
    
  }  




  
  handleSetPageColor = (type)  => () => {
    
    const currentpageColor=this.state.pageColor;
   
    if(type=='change')
    {
      if(currentpageColor=='sun')
      {
        localStorage.setItem('pageColor','moon');
 
        this.setState({ pageColor: 'moon' });
      }
      else{
        localStorage.setItem('pageColor','sun');
 
        this.setState({ pageColor: 'sun' });
      }
    }
    else{

      localStorage.setItem('pageColor',type);
  
      this.setState({ pageColor: type });
    }
  
  }

 

  render() {
    const { children,t, authuserinfo,companyinfo,checkPayments,countusers } = this.props;
    const { pageColor, } = this.state;
    const handleSetPageColor=this.handleSetPageColor;

    const accesscompany=companyinfo && companyinfo.access && companyinfo.access.length && companyinfo.access[0].name.name;
    const accesscompanyenddate=companyinfo && companyinfo.access && companyinfo.access.length && companyinfo.access[0].end;
    const expiredcompany=accesscompany!='Full' && accesscompanyenddate< moment().format("YYYY-MM-DD H:mm:ss");
    const currenturl=window.location.pathname;
    const redirect=`/settings/company/${companyinfo.id}/invoices`;
    const roleCustomer = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='Customer').length && companyinfo.id==authuserinfo.company_id;
    const roleUser = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='User').length && companyinfo.id==authuserinfo.company_id;

  

    if (roleCustomer && expiredcompany &&currenturl!=redirect  && currenturl!=`/settings/company/${companyinfo.id}/subscriptions` && currenturl!='/logout' && currenturl!='/logoutsuccess' && currenturl!='/login') {
    
      return <Redirect to={`/settings/company/${companyinfo.id}/invoices`} />;
    }

    if (roleUser && expiredcompany &&currenturl!=redirect  && currenturl!='/logout' && currenturl!='/logoutsuccess' && currenturl!='/login') {
     
      return <Redirect to={`/logout`} />;
    }

    if(roleUser && authuserinfo.active==0 && currenturl!='/logout' && currenturl!='/logoutsuccess' && currenturl!='/login'){
      return <Redirect to={`/logout`} />;
    }
 
    
    const sidebarColor=authuserinfo && authuserinfo.company && authuserinfo.company.sidebarcolor;

    return (
      <div>
        <PageContext.Provider value={{t,pageColor,sidebarColor,handleSetPageColor}} >
          <GlobalStyle />
          <ThemeProvider  theme={theme}>{children}</ThemeProvider>
        </PageContext.Provider>
      </div>
    );
  }
}

MainTemplate.propTypes = {
  children: PropTypes.element.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};


const mapStateToProps = state => {

  const {  authuserinfo,companyinfo,countusers} = state;
  
  return {  authuserinfo,companyinfo,countusers};
};

const mapDispatchToProps = dispatch => ({
  
  getAuthUserInfo: () => dispatch(getAuthUserInfoAction()),
  checkPayments: () => dispatch(checkPaymentsAction()),
  disableActiveUsers:(countusers,accessusers)=> dispatch(disableActiveUsersAction(countusers,accessusers)),
});

export default translate(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(MainTemplate)));
