import React  from 'react'

import Settings from 'views/Settings';
import Statistics from 'components/organisms/EditUsers/Statistics';



const UserStatistics = ({match}) => {

  
    return (
    <Settings>
       
      <Statistics id={match.params.id}/>
     

   
    </Settings>
    )


}



export default UserStatistics;