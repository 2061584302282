import React, {Component}   from 'react'
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { Redirect, NavLink } from 'react-router-dom';
import {routes} from 'routes';
import Paragraph from 'components/atoms/Paragraph/Paragraph';
import Heading from 'components/atoms/Heading/Heading';
import Button from 'components/atoms/Button/Button';
import Input from 'components/atoms/Input/Input';
import Label from 'components/atoms/Input/Label';
import Inputbox from 'components/atoms/Input/Inputbox';

import Select from 'components/atoms/Input/Select';
import DefaultTable from 'components/molecules/Table/DefaultTable';
import SmallSpinner from 'components/atoms/Spinner/SmallSpinner';
import Card from 'components/molecules/Card/Card';
import HeaderWrapper from 'components/atoms/HeaderWrapper/HeaderWrapper';
import InnerWrapper from 'components/atoms/InnerWrapper/InnerWrapper';
import Buttons from 'components/atoms/Buttons/Buttons';
import Checkbox2 from 'components/atoms/Input/Checkbox2';

import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';

import {  getUserInfo as getUserInfoAction } from 'actions/user';
import { editAssignedEvents as editAssignedEventsAction, fetchEventsLogs as fetchEventsLogsAction} from 'actions/event';
import ListMenu from 'components/atoms/ListMenu/ListMenu';
import Item from 'components/atoms/ListMenu/Item';

import {clearMessage as clearMessageAction} from 'actions';
import { connect } from 'react-redux';
import withContext from 'hoc/withContext';
import gsap from "gsap";
import {alerts} from 'helpers/alerts';

import * as Yup from 'yup'; 


const StyledWrapper = styled.div`
  display:flex;
`;

const StyledEventList = styled.ul`
  display:flex;
  flex-direction:column;
  list-style: none;
  margin: 0;
  padding: 0;

`;




const StyledEvent = styled.li`
  display:flex;
  justify-content: space-between;
  padding:10px;
`;

const StyledParagraph=styled(Paragraph)`
  min-width:400px;
  padding-top:15px;
`;



class EventsLogs extends Component {

  constructor(){
    super();
    this.ShowUserInfoWrap = null;
    this.FormUserInfoWrap = null;
  
    this.tlUserInfo = null;
    this.FormUserInfoWrap= null;
   
}

state={
  redirect:null,

}


  componentDidMount(){

    const { id,getUserInfo,fetchEventsLogs, clearMessage } = this.props;
    clearMessage();

    getUserInfo(id);
    fetchEventsLogs(id);
   
   
   

    this.tlUserInfo = gsap.timeline({defaults:{ease:'power3.inOut'}})
        .set([this.FormUserInfoWrap], {autoAlpha: 0})
       .fromTo(this.FormUserInfoWrap, { y: "-100"}, {duration: 1, y:"+=100", autoAlpha:1})
   
    
    }


  

    render() {
       
        const { id, editAssignedEvents,isLoadingContent, assignedeventstouser,getuserinfo,eventslogs, pageContext, message, authuserinfo,clearMessage} =this.props;
        const {redirect, listofevents}=this.state;

        const permission = authuserinfo && authuserinfo.workplace && authuserinfo.workplace.permissions  && authuserinfo.workplace.permissions.filter(perm=>perm.name=='Users').length;
        const roleAdmin = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='SuperAdmin').length;
        const roleCustomer = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='Customer').length;

 

    
      const headers=[];
      headers.push("Nazwa wydarzenia","Przypisał","Przed zmianą","Po zmianie","Data")
  
      const body=[];
   
      eventslogs.map(event=>(
        body.push({name:event.name.name, surname:event.admin_username, hoursbefore:event.hours_before+"h", hoursafter:event.hours_after+"h", date:event.created_at})
      ));
      
     
        if(redirect)
        {
          return <Redirect to={redirect} />;
        }

        return (
          

         
            <StyledWrapper ref={div => this.FormUserInfoWrap = div}>
             {permission || roleAdmin || roleCustomer ?
          
            <Card pagecolor={pageContext.pageColor}>
            <HeaderWrapper  activeColor={pageContext.sidebarColor}>
              <Heading>Logi wydarzeń: {getuserinfo.name} </Heading>
              
             
            </HeaderWrapper>
            <Formik enableReinitialize
            initialValues={{listofevents:listofevents }}
           // validationSchema={EditUserSchema}
    
            onSubmit={values => {
            editAssignedEvents(listofevents, id);
           //  this.setState({ redirect: `/settings/company/${id}/events` });

             
            }}
      
            >  
            {({ values, handleChange, handleBlur,errors, touched, setFieldValue }) => (
              <Form>
              <InnerWrapper  pagecolor={pageContext.pageColor}>
             
               
              {!isLoadingContent ? 
                <DefaultTable type="users" headers={headers} body={body}/>
                : <SmallSpinner activecolor={pageContext.sidebarColor} className="loader" />}

              </InnerWrapper>
              
              <Buttons pagecolor={pageContext.pageColor} activeColor={pageContext.sidebarColor}>
              
                <Button type="button"
                as={NavLink} to={`/settings/users/${id}/events`} medium back >
                {pageContext.t('button.back')}
                </Button>
    
               
              </Buttons>
              </Form>
            )}
            </Formik>
          </Card>
          
           
          :   ''}
          </StyledWrapper>
      
         
        )
    }
}

const mapStateToProps = (state, ownProps) => {


  const { message,eventslogs, getuserinfo,authuserinfo, isLoadingContent  } = state;
  
    return {
     
      message,eventslogs, getuserinfo, authuserinfo, isLoadingContent
    };
  

};

const mapDispatchToProps = dispatch => ({
  getUserInfo: (id) => dispatch(getUserInfoAction(id)),
  fetchEventsLogs: (id) => dispatch(fetchEventsLogsAction(id)),
    
  clearMessage: () =>dispatch(clearMessageAction())
}); 
 
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withContext(EventsLogs));