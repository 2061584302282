import styled, {css} from 'styled-components';

const Paragraph = styled.p`
    font-size: ${({theme}) => theme.fontSize.s};
    font-weight: ${({theme}) =>theme.regular}; 

    ${({ medium}) =>
    medium &&
    css`
    font-size: ${({theme}) => theme.fontSize.m};
    font-weight: ${({theme}) =>theme.bold}; 
    
        ${({ theme }) => theme.mq.mobile} {
            font-size: ${({theme}) => theme.fontSize.s};
        }
    `}

    ${({ small}) =>
    small &&
    css`
    font-size: ${({theme}) => theme.fontSize.xs};
    font-weight: ${({theme}) =>theme.regular}; 
    
        ${({ theme }) => theme.mq.mobile} {
            font-size: ${({theme}) => theme.fontSize.xxs};
        }
    `}

    a {
        color:${({ activecolor}) => activecolor};
        text-decoration: underline;
    }
    a:hover{
        color:${({ activecolor}) => activecolor};
        text-decoration: none;
    }
`;

export default Paragraph;