import React, {Component}   from 'react'
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { Redirect, NavLink } from 'react-router-dom';
import {routes} from 'routes';
import Paragraph from 'components/atoms/Paragraph/Paragraph';
import Heading from 'components/atoms/Heading/Heading';
import Button from 'components/atoms/Button/Button';
import Input from 'components/atoms/Input/Input';
import Label from 'components/atoms/Input/Label';
import Inputbox from 'components/atoms/Input/Inputbox';

import Select from 'components/atoms/Input/Select';
import DefaultTable from 'components/molecules/Table/DefaultTable';
import SmallSpinner from 'components/atoms/Spinner/SmallSpinner';
import Card from 'components/molecules/Card/Card';
import HeaderWrapper from 'components/atoms/HeaderWrapper/HeaderWrapper';
import InnerWrapper from 'components/atoms/InnerWrapper/InnerWrapper';
import Buttons from 'components/atoms/Buttons/Buttons';
import Checkbox2 from 'components/atoms/Input/Checkbox2';

import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';

import {  getUserInfo as getUserInfoAction } from 'actions/user';
import { editAssignedEvents as editAssignedEventsAction } from 'actions/event';
import ListMenu from 'components/atoms/ListMenu/ListMenu';
import Item from 'components/atoms/ListMenu/Item';

import {clearMessage as clearMessageAction} from 'actions';
import { connect } from 'react-redux';
import withContext from 'hoc/withContext';
import gsap from "gsap";
import {alerts} from 'helpers/alerts';

import * as Yup from 'yup'; 


const StyledWrapper = styled.div`
  display:flex;
`;

const StyledEventList = styled.ul`
  display:flex;
  flex-direction:column;
  list-style: none;
  margin: 0;
  padding: 0;

`;

const StyledMenu = styled(Card)`
  height: 100%;
  margin-left:20px;

    ${({ theme }) => theme.mq.standard} {
      margin: 0 0 10px 0;
      height: 100px;
    
    }
    ${({ theme }) => theme.mq.mobile} {
      margin: 0 0 10px 0;
      height: 100%;
    
    }
 
`;


const StyledEvent = styled.li`
  display:flex;
  justify-content: space-between;
  padding:10px;
`;

const StyledParagraph=styled(Paragraph)`
  min-width:400px;
  padding-top:15px;
`;



class CreateForm extends Component {

  constructor(){
    super();
    this.ShowUserInfoWrap = null;
    this.FormUserInfoWrap = null;
  
    this.tlUserInfo = null;
    this.FormUserInfoWrap= null;
   
}

state={
  redirect:null,
  listofevents:[]
}

  componentDidUpdate(prevProps)
  {
      const { getuserinfo } = this.props;
     
      if(prevProps.getuserinfo!=getuserinfo )
      {   
         
          const isAssignedevents=getuserinfo && getuserinfo.assignedevents;
          const currentpermid=isAssignedevents&&isAssignedevents   ;
          const newArr = currentpermid.map(v => ({...v, hours: Math.floor(v.hours),minutes:Math.round((v.hours-Math.floor(v.hours))*60)}))

          if(currentpermid)
          {
          this.setState({ 
            listofevents:
                
            newArr
              
            
            });
          }
      }
  }

  componentDidMount(){

    const { id,getUserInfo, clearMessage } = this.props;
    clearMessage();

    getUserInfo(id);
   
   
   

    this.tlUserInfo = gsap.timeline({defaults:{ease:'power3.inOut'}})
        .set([this.FormUserInfoWrap], {autoAlpha: 0})
       .fromTo(this.FormUserInfoWrap, { y: "-100"}, {duration: 1, y:"+=100", autoAlpha:1})
   
    
    }


    handleChangeColorOnPicker = (color) => {
        this.setState({ background: color.hex });
      };

      eventChangeToActive = (data) =>{
       
        const elementsIndex = this.state.listofevents.findIndex(element => element.event_id === data )
        let newArray = [...this.state.listofevents];

        let newactive=0;
        newArray[elementsIndex].active==1 ? newactive=0 : newactive=1;

        newArray[elementsIndex] = {...newArray[elementsIndex], active:newactive}

  
        this.setState({
          listofevents: newArray
      
        });

    
      }

      eventChangeHours = (e,data) =>{
        const elementsIndex = this.state.listofevents.findIndex(element => element.event_id === data )
        let newArray = [...this.state.listofevents];
        newArray[elementsIndex] = {...newArray[elementsIndex], hours:e.target.value}

  
        this.setState({
          listofevents: newArray
      
        });
     
    
      }

      eventChangeMinutes = (e,data) =>{
        const elementsIndex = this.state.listofevents.findIndex(element => element.event_id === data )
        let newArray = [...this.state.listofevents];
        newArray[elementsIndex] = {...newArray[elementsIndex], minutes:e.target.value}

  
        this.setState({
          listofevents: newArray
      
        });
     
    
      }

  

    render() {
       
        const { id, editAssignedEvents,isLoadingContent, assignedeventstouser,getuserinfo, pageContext, message, authuserinfo,clearMessage} =this.props;
        const {redirect, listofevents}=this.state;

        const permission = authuserinfo && authuserinfo.workplace && authuserinfo.workplace.permissions  && authuserinfo.workplace.permissions.filter(perm=>perm.name=='Users').length;
        const roleAdmin = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='SuperAdmin').length;
        const roleCustomer = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='Customer').length;

      const isAssignedevents=getuserinfo && getuserinfo.assignedevents && getuserinfo.assignedevents.length;
      const Assignedevents=getuserinfo && getuserinfo.assignedevents;

    
   
      
     
        if(redirect)
        {
          return <Redirect to={redirect} />;
        }

        return (
          

         
            <StyledWrapper ref={div => this.FormUserInfoWrap = div}>
             {permission || roleAdmin || roleCustomer ?
             <>
            <Card pagecolor={pageContext.pageColor}>
            <HeaderWrapper  activeColor={pageContext.sidebarColor}>
              <Heading>{pageContext.t('card.employeeevents')}: {getuserinfo.name} </Heading>
              
             
            </HeaderWrapper>
            <Formik enableReinitialize
            initialValues={{listofevents:listofevents }}
           // validationSchema={EditUserSchema}
    
            onSubmit={values => {
            editAssignedEvents(listofevents, id);
           //  this.setState({ redirect: `/settings/company/${id}/events` });

             
            }}
      
            >  
            {({ values, handleChange, handleBlur,errors, touched, setFieldValue }) => (
              <Form>
              <InnerWrapper  pagecolor={pageContext.pageColor}>
             
              {message=='hourisnotnumber' ? alerts(pageContext,'card.validate',message,'') : alerts(pageContext,'alerts.',message,'success')}
              

               {!isLoadingContent ? 
                
                <StyledEventList>
               {listofevents.map(event=>{
             

               return (
                    <StyledEvent><StyledParagraph>{event.name.name} ({event.name.limit==0 ? pageContext.t('event.withoutlimit'): pageContext.t('event.withlimit')})</StyledParagraph>
                    
                    <Inputbox>
                      <Input card
                      type="text"
                      name="hours"
                      placeholder={pageContext.t('hours')}
                      onChange={(e)=>this.eventChangeHours(e,event.event_id)}
                      onBlur={handleBlur}
                      value={event.hours} 
                     disabled={event.active==0 ? 'disabled' : ''}
                      className={`form-control ${
                        errors.hours && touched.hours ? "is-invalid" : ""
                    }`}
                    />
                    <Label>{pageContext.t('counthours')}</Label>
                </Inputbox>

                    {errors.hours && touched.hours ? (
                      <ErrorMessage>{pageContext.t(`card.validate.${errors.hours}`)}</ErrorMessage>
                    ) : null}

                    <Inputbox>
                      <Input card
                      type="text"
                      name="minutes"
                      placeholder={pageContext.t('minutes')}
                      onChange={(e)=>this.eventChangeMinutes(e,event.event_id)}
                      onBlur={handleBlur}
                      value={event.minutes} 
                     disabled={event.active==0 ? 'disabled' : ''}
                      className={`form-control ${
                        errors.minutes && touched.minutes ? "is-invalid" : ""
                    }`}
                    />
                    <Label>{pageContext.t('countminutes')}</Label>
                </Inputbox>

                    <Field
                    type="checkbox"
                    name='active'
                    id={event.event_id}
                    label=""
                    filter={event.active}
                    addToFilter={this.eventChangeToActive}
                    
                    setFieldValue={setFieldValue}
                    component={Checkbox2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    />
                    </StyledEvent>
               )
               })
                
               }
               </StyledEventList>
                : <SmallSpinner activecolor={pageContext.sidebarColor} className="loader" />}
                
               

              </InnerWrapper>
              
              <Buttons pagecolor={pageContext.pageColor} activeColor={pageContext.sidebarColor}>
              
                <Button type="button"
                as={NavLink} to={`/settings/users/${id}`} medium back >
                {pageContext.t('button.back')}
                </Button>
    
                <Button type="submit" medium mediumaccept activecolor={pageContext.sidebarColor} >
                {pageContext.t('button.save')}
                </Button>
              </Buttons>
              </Form>
            )}
            </Formik>
          </Card>
           <StyledMenu pagecolor={pageContext.pageColor}>
              <HeaderWrapper  activeColor={pageContext.sidebarColor}>
                <Heading>Menu</Heading>
              </HeaderWrapper>

              <ListMenu pagecolor={pageContext.pageColor}>
               
                <Item><Button as={NavLink} to={`/settings/users/${id}/eventslogs`} activecolor={pageContext.sidebarColor} small>Logi</Button></Item>
                

              </ListMenu>
            </StyledMenu>
            </>
          :   ''}
          </StyledWrapper>
      
         
        )
    }
}

const mapStateToProps = (state, ownProps) => {


  const { message, getuserinfo,authuserinfo, isLoadingContent  } = state;
  
    return {
     
      message, getuserinfo, authuserinfo, isLoadingContent
    };
  

};

const mapDispatchToProps = dispatch => ({
  getUserInfo: (id) => dispatch(getUserInfoAction(id)),
  editAssignedEvents:(values,id)=>dispatch(editAssignedEventsAction(values,id)),
    
  clearMessage: () =>dispatch(clearMessageAction())
}); 
 
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withContext(CreateForm));