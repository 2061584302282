import React  from 'react'

import Settings from 'views/Settings';
import CompaniesList from 'components/organisms/Customers/List';



const Customers = () => {



    return (
    
    <Settings>
  
      
        <CompaniesList/>
      
    </Settings>
         
    )


}


export default Customers;