import React from 'react';

import styled, { css } from 'styled-components';


const ButtonsInTable = styled.div`
     display:flex;
   
  
   
   
   justify-content: space-between;
`;


export default ButtonsInTable;
