import axios from 'axios';
import {url, OptionsAuth} from 'actions'


export const FETCHEVENTS_REQUEST = 'FETCHEVENTS_REQUEST';
export const FETCHEVENTS_SUCCESS = 'FETCHEVENTS_SUCCESS';
export const FETCHEVENTS_FAILURE = 'FETCHEVENTS_FAILURE';

export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE';

export const GET_ASSIGNED_EVENTS_TO_USER_REQUEST = 'GET_ASSIGNED_EVENTS_TO_USER_REQUEST';
export const GET_ASSIGNED_EVENTS_TO_USER_SUCCESS = 'GET_ASSIGNED_EVENTS_TO_USERS_SUCCESS';
export const GET_ASSIGNED_EVENTS_TO_USER_FAILURE = 'GET_ASSIGNED_EVENTS_TO_USER_FAILURE';


export const EDIT_EVENT_REQUEST = 'EDIT_EVENT_REQUEST';
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS';
export const EDIT_EVENT_FAILURE = 'EDIT_EVENT_FAILURE';

export const SEND_MESSAGE_TO_SLACK_REQUEST = 'SEND_MESSAGE_TO_SLACK_REQUEST';
export const SEND_MESSAGE_TO_SLACK_FAILURE = 'SEND_MESSAGE_TO_SLACK_FAILURE';

export const EDIT_ASSIGNED_EVENTS_REQUEST = 'EDIT_ASSIGNED_EVENTS_REQUEST';
export const EDIT_ASSIGNED_EVENTS_SUCCESS = 'EDIT_ASSIGNED_EVENTS_SUCCESS';
export const EDIT_ASSIGNED_EVENTS_FAILURE = 'EDIT_ASSIGNED_EVENTS_FAILURE';

export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';

export const REMOVE_EVENT_REQUEST = 'REMOVE_EVENT_REQUEST';
export const REMOVE_EVENT_SUCCESS = 'REMOVE_EVENT_SUCCESS';
export const REMOVE_EVENT_FAILURE = 'REMOVE_EVENT_FAILURE';

export const REMOVE_ITEM_ACCEPTED_REQUEST = 'REMOVE_ITEM_ACCEPTED_REQUEST';
export const REMOVE_ITEM_ACCEPTED_SUCCESS = 'REMOVE_ITEM_ACCEPTED_SUCCESS';
export const REMOVE_ITEM_ACCEPTED_FAILURE = 'REMOVE_ITEM_ACCEPTED_FAILURE';

export const FETCHEVENTSFROMCALENDAR_REQUEST = 'FETCHEVENTSFROMCALENDAR_REQUEST';
export const FETCHEVENTSFROMCALENDAR_SUCCESS = 'FETCHEVENTSFROMCALENDAR_SUCCESS';
export const FETCHEVENTSFROMCALENDAR_FAILURE = 'FETCHEVENTSFROMCALENDAR_FAILURE';

export const FETCHTOASTS_REQUEST = 'FETCHTOASTS_REQUEST';
export const FETCHTOASTS_SUCCESS = 'FETCHTOASTS_SUCCESS';
export const FETCHTOASTS_FAILURE = 'FETCHTOASTS_FAILURE';

export const FETCH_EVENTS_LOGS_REQUEST = 'FETCH_EVENTS_LOGS_REQUEST';
export const FETCH_EVENTS_LOGS_SUCCESS = 'FETCH_EVENTS_LOGS_SUCCESS';
export const FETCH_EVENTS_LOGS_FAILURE = 'FETCH_EVENTS_LOGS_FAILURE';

export const UPDATETOASTS_REQUEST = 'UPDATETOASTS_REQUEST';
export const UPDATETOASTS_SUCCESS = 'UPDATETOASTS_SUCCESS';
export const UPDATETOASTS_FAILURE = 'UPDATETOASTS_FAILURE';

export const ADD_ITEM_TO_CALENDAR_REQUEST = 'ADD_ITEM_TO_CALENDAR_REQUEST';
export const ADD_ITEM_TO_CALENDAR_SUCCESS = 'ADD_ITEM_TO_CALENDAR_SUCCESS';
export const ADD_ITEM_TO_CALENDAR_FAILURE = 'ADD_ITEM_TO_CALENDAR_FAILURE';

export const CHANGE_ITEM_STATUS_REQUEST = 'CHANGE_ITEM_STATUS_REQUEST';
export const CHANGE_ITEM_STATUS_FAILURE = 'CHANGE_ITEM_STATUS_FAILURE';
export const CHANGE_ITEM_STATUS_SUCCESS = 'CHANGE_ITEM_STATUS_SUCCESS';

export const fetchToasts = () => (dispatch, getState) => {
    dispatch({ type: FETCHTOASTS_REQUEST });
  
    OptionsAuth(getState().token);
  
    return axios
      .get(`${url}/api/v1/events/gettoasts`, {
       
      })
      .then(({data}) => {
        //console.log(data.data)
        dispatch({
          type: FETCHTOASTS_SUCCESS,
          payload: {
           data
          }
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: FETCHTOASTS_FAILURE });
      });
  };

  
export const fetchEventsFromCalendar = () => (dispatch, getState) => {
    dispatch({ type: FETCHEVENTSFROMCALENDAR_REQUEST });
  
    OptionsAuth(getState().token);
  
    return axios
      .get(`${url}/api/v1/events/geteventsfromcalendar`, {
       
      })
      .then(({data}) => {
       
        dispatch({
          type: FETCHEVENTSFROMCALENDAR_SUCCESS,
          payload: {
           data
          }
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: FETCHEVENTSFROMCALENDAR_FAILURE });
      });
  };
  
     
export const fetchEventsUserFromCalendar = (id) => (dispatch, getState) => {
  dispatch({ type: FETCHEVENTSFROMCALENDAR_REQUEST });

  OptionsAuth(getState().token);

  return axios
    .get(`${url}/api/v1/events/geteventsuserfromcalendar/${id}`, {
     
    })
    .then(({data}) => {
     
      dispatch({
        type: FETCHEVENTSFROMCALENDAR_SUCCESS,
        payload: {
         data
        }
      });
    })
    .catch(err => {
      console.log(err.response);
      dispatch({ type: FETCHEVENTSFROMCALENDAR_FAILURE });
    });
};

  
  export const fetchEvents = () => (dispatch, getState)  => {
    dispatch({ type: FETCHEVENTS_REQUEST });
    OptionsAuth(getState().token);
    return axios
      .get(`${url}/api/v1/events/fetchevents`)
      .then(({data}) => {
       
        dispatch({
          type: FETCHEVENTS_SUCCESS,
          payload: {
           data
          }
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: FETCHEVENTS_FAILURE });
      });
  };

  export const fetchUserEvents = (id) => (dispatch, getState)  => {
    dispatch({ type: FETCHEVENTS_REQUEST });
    OptionsAuth(getState().token);
    return axios
      .get(`${url}/api/v1/events/fetchuserevents/${id}`)
      .then(({data}) => {
       
        dispatch({
          type: FETCHEVENTS_SUCCESS,
          payload: {
           data
          }
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: FETCHEVENTS_FAILURE });
      });
  };

  export const getEvents = (id) => (dispatch, getState)  => {
    dispatch({ type: GET_EVENTS_REQUEST });
    OptionsAuth(getState().token);
    return axios
      .get(`${url}/api/v1/events/getevents/${id}`)

      .then(({data}) => {
        
        dispatch({
          type: GET_EVENTS_SUCCESS,
          payload: {
           data
          }
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: GET_EVENTS_FAILURE });
      });
  };

  export const editEvent = (values, id) => (dispatch, getState) => {
    dispatch({ type: EDIT_EVENT_REQUEST });
  
    OptionsAuth(getState().token);

    return axios
      .post(`${url}/api/v1/events/edit/${id}`, {
      
        values
     
        
      })
      .then(({data}) => {

       dispatch({
          type: EDIT_EVENT_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: EDIT_EVENT_FAILURE });
      });
  };


  export const createEvent = (values, id) => (dispatch, getState) => {
    dispatch({ type: CREATE_EVENT_REQUEST });
  
    OptionsAuth(getState().token);

    return axios
      .post(`${url}/api/v1/events/create/${id}`, {
      
        values
    
        
      })
      .then(({data}) => {

      
        dispatch({
          type: CREATE_EVENT_SUCCESS,
          payload: {
           
            data,
          },
          });
        

      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: CREATE_EVENT_FAILURE });
      });
  };

  
  export const  editAssignedEvents = (values, id) => (dispatch, getState) => {
    dispatch({ type: EDIT_ASSIGNED_EVENTS_REQUEST });
  
    OptionsAuth(getState().token);

    return axios
      .post(`${url}/api/v1/events/editassigned/${id}`, {
      
        values
    
        
      })
      .then(({data}) => {

       dispatch({
          type: EDIT_ASSIGNED_EVENTS_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: EDIT_ASSIGNED_EVENTS_FAILURE });
      });
  };


  export const removeEvent = ( id) => (dispatch, getState) => {
    dispatch({ type: REMOVE_EVENT_REQUEST });
  
    OptionsAuth(getState().token);

    return axios
      .get(`${url}/api/v1/events/remove/${id}`, {
      
      
    
        
      })
      .then(({data}) => {

       dispatch({
          type: REMOVE_EVENT_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: REMOVE_EVENT_FAILURE });
      });
  };

 
  
  
  
  export const addItemToCalendar = (values,userid,diff) => (dispatch, getState) => {
    dispatch({ type: ADD_ITEM_TO_CALENDAR_REQUEST });
  
    OptionsAuth(getState().token);

    return axios
      .post(`${url}/api/v1/events/additem`, {
     
        values,userid,diff
      })
      .then(({data}) => {

       dispatch({
          type: ADD_ITEM_TO_CALENDAR_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: ADD_ITEM_TO_CALENDAR_FAILURE });
      });
  };

  export const sendMessageToSlack = (webhook,channel,surname,user,namerequest,link,type,date,hours,comment,avatar) => (dispatch, getState) => {
    dispatch({ type: SEND_MESSAGE_TO_SLACK_REQUEST });
  
  
  
    return axios
      .post(webhook, {
     
        "channel": channel,
         "username": "Calendar",
         "icon_emoji": ":spiral_calendar_pad:",
         "blocks": [
          {
            "type": "section",
            "text": {
              "type": "mrkdwn",
              "text": `*${surname}* ${namerequest}:\n*<${link}|Interactivevision - Calendar>*`
            }
          },
          {
            "type": "section",
            "text": {
              "type": "mrkdwn",
              "text": `*User:*\n ${user} \n *Type:*\n ${type} \n *When:*\n ${date}\n *Hours:* ${hours}\n ${comment ? `*Comments:* ${comment}` : '' }`
            },
            "accessory": {
              "type": "image",
              "image_url": avatar,
              "alt_text": "computer thumbnail"
            }
          }
        ]
        
      })
      .then(({data}) => {
    
      /* dispatch({
          type: ADD_ITEM_TO_CALENDAR_SUCCESS,
          payload: {
           
            data,
          },
        });*/
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: SEND_MESSAGE_TO_SLACK_FAILURE });
      });
  };
  
  export const changeItemStatus = (id,userid,status,diff) => (dispatch, getState) => {
    dispatch({ type: CHANGE_ITEM_STATUS_REQUEST });
  
    OptionsAuth(getState().token);
 
    return axios
      .post(`${url}/api/v1/events/changeitemstatus`, {
     
        id,userid,status,diff
      })
      .then(({data}) => {
  
       dispatch({
          type: CHANGE_ITEM_STATUS_SUCCESS,
          payload: {
           
            data
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: CHANGE_ITEM_STATUS_FAILURE,error:err});
        
      });
  };

  export const removeItemAccepted = (id,userid,diffhours) => (dispatch, getState) => {
    dispatch({ type: REMOVE_ITEM_ACCEPTED_REQUEST });
  
    OptionsAuth(getState().token);
 
    return axios
      .post(`${url}/api/v1/events/removeitemaccepted/${id}`, {
        userid,diffhours
       
      })
      .then(({data}) => {
    
       dispatch({
          type: REMOVE_ITEM_ACCEPTED_SUCCESS,
          payload: {
           
            data
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: REMOVE_ITEM_ACCEPTED_FAILURE});
        
      });
  };
  
  export const updateToastStatus = (data) => (dispatch, getState) => {
    dispatch({ type: UPDATETOASTS_REQUEST });
  
    OptionsAuth(getState().token);
  
    return axios
      .post(`${url}/api/v1/events/updatetoaststatus`, {
     
        data
      })
      .then(({data}) => {
      
       dispatch({
          type: UPDATETOASTS_SUCCESS,
          payload: {
           data
          }
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: UPDATETOASTS_FAILURE });
      });
  };
  

  export const fetchEventsLogs = ( id) => (dispatch, getState) => {
    dispatch({ type: FETCH_EVENTS_LOGS_REQUEST });
  
    OptionsAuth(getState().token);

    return axios
      .get(`${url}/api/v1/events/fetcheventslogs/${id}`, {
      
      
    
        
      })
      .then(({data}) => {
console.log(data)
       dispatch({
          type: FETCH_EVENTS_LOGS_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
        console.log(err.response);
        dispatch({ type: FETCH_EVENTS_LOGS_FAILURE });
      });
  };


