export const routes = {
  home: '/',
  calendar:'/calendar',
  statistics:'/statistics',
  notifications: '/notifications',
  settings:{
    home:'/settings',
    avatar:'/settings/avatar',
    person:'/settings/person',
    users:{
      home:'/settings/users',
      edituser: '/settings/users/:id',
      edituserform: '/settings/users/:id/edit',
      adduserform: '/settings/users/:id/create',
      events: '/settings/users/:id/events',
      eventslogs: '/settings/users/:id/eventslogs',
      calendar: '/settings/users/:id/calendar',
      statistics: '/settings/users/:id/statistics',
      questionnaire:'/settings/users/:id/questionnaire',
      contracts:'/settings/users/:id/contracts',
      bhps:'/settings/users/:id/bhps',
      medicals:'/settings/users/:id/medicals',
      responsibility:'/settings/users/:id/responsibility',
      companystuff:'/settings/users/:id/companystuff',
      documents:'/settings/users/:id/documents'

    },
    customers:{
      home:'/settings/customers',
      
      create:'/settings/customers/create',
    },
    company:{
      
      info:'/settings/company/:id',
      logotype:'/settings/company/:id/logotype',
      maincolor:'/settings/company/:id/maincolor',
      edit:'/settings/company/:id/edit',
      slack:'/settings/company/:id/slack',
      invoices:{
        home:'/settings/company/:id/invoices',
        details:'/settings/company/:id/invoices/:payment'
      },
      workplaces:{
        home: '/settings/company/:id/workplaces',
        edit: '/settings/company/:id/workplaces/edit/:workplaceid',
        create: '/settings/company/:id/workplaces/create',
        permissions:'/settings/company/:id/workplaces/permissions/:workplaceid'
      },
      events:{
        home:'/settings/company/:id/events',
        edit: '/settings/company/:id/events/edit/:eventid',
        create: '/settings/company/:id/events/create',
      },
      subscriptions:'/settings/company/:id/subscriptions'
      
    }

  },
   

  login: '/login',
  activation: '/activation/:token',
  register: '/register',
  logout:'/logout',
  logoutsuccess:'/logoutsuccess',
  error404:'/404'
};
