import React from 'react';
import styled, {css} from 'styled-components';


const Wrapper = styled.div`
 
   padding:10px;
 
`;
const RadioDisability = ({field,name, label, id,filter, addToFilter})=>{

    return (
    <Wrapper>
    <input   type="radio"  name={field.name} value={id} 
      checked={((filter==id) || (filter==0 && id=='brak')) ? true : false }
     
     className="checkbox" id={id}/>
    <label onClick={()=>addToFilter(id)} for={id}>{label}</label>
    </Wrapper>
    )
}

   


export default RadioDisability;


