import axios from 'axios';
import React from 'react';
import {url, OptionsAuth} from 'actions'

export const FETCH_INVOICES_REQUEST = 'FETCH_INVOICES_REQUEST';
export const FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';

export const FETCH_SUBSCRIPTIONS_REQUEST = 'FETCH_SUBSCRIPTIONS_REQUEST';
export const FETCH_SUBSCRIPTIONS_FAILURE = 'FETCH_SUBSCRIPTIONS_FAILURE';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'FETCH_SUBSCRIPTIONS_SUCCESS';

export const CHECK_ORDER_STATUS_REQUEST = 'CHECK_ORDER_STATUS_REQUEST';
export const CHECK_ORDER_STATUS_FAILURE = 'CHECK_ORDER_STATUS_FAILURE';
export const CHECK_ORDER_STATUS_SUCCESS = 'CHECK_ORDER_STATUS_SUCCESS';

export const NEW_ORDER_PAYU_REQUEST= 'NEW_ORDER_PAYU_REQUEST';

export const CHECK_PAYMENTS_REQUEST= 'CHECK_PAYMENTS_REQUEST';
export const CHECK_PAYMENTS_FAILURE = 'CHECK_PAYMENTS_FAILURE';
export const CHECK_PAYMENTS_SUCCESS = 'CHECK_PAYMENTS_SUCCESS';

export const CANCEL_ORDER_REQUEST= 'CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_FAILURE = 'CANCEL_ORDER_FAILURE';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';

export const fetchSubscriptions = () => (dispatch, getState) => {
    dispatch({ type: FETCH_SUBSCRIPTIONS_REQUEST });
  
    OptionsAuth(getState().token);
 
    return axios
      .get(`${url}/api/v1/subscription/fetch`, {
      
       
        
      })
      .then(({data}) => {

       dispatch({
          type:FETCH_SUBSCRIPTIONS_SUCCESS,
          payload: {
           
            data,
          },
        });
      })
      .catch(err => {
      //  console.log(err.response);
        dispatch({ type: FETCH_SUBSCRIPTIONS_FAILURE });
      });
  };





  export const newOrderPayu = (tok) => (dispatch, getState) => {
    dispatch({ type: NEW_ORDER_PAYU_REQUEST });
  
    OptionsAuth(getState().token);
   
 
    return axios
      .post(`${url}/api/v1/subscription/payu/new_order`, {
      tok
      })
      .then(({data}) => {

     console.log(data)
      })
      .catch(err => {
       console.log(err.response);
        dispatch({ type: FETCH_SUBSCRIPTIONS_FAILURE });
      });
  };


  export const checkOrderStatus = (id) => (dispatch, getState) => {
    dispatch({ type: CHECK_ORDER_STATUS_REQUEST });
  
    OptionsAuth(getState().token);
   
 
    return axios
      .get(`${url}/api/v1/subscription/checkorderstatus/${id}`, {
     
      })
      .then(({data}) => {

    // console.log(data)
     dispatch({
      type:  CHECK_ORDER_STATUS_SUCCESS,
      payload: {
       
        data,
      },
    });
      })
      .catch(err => {
       console.log(err.response);
        dispatch({ type: CHECK_ORDER_STATUS_FAILURE });
      });
  };

  export const cancelOrder = (id) => (dispatch, getState) => {
    dispatch({ type: CANCEL_ORDER_REQUEST });
  
    OptionsAuth(getState().token);
   
 
    return axios
      .get(`${url}/api/v1/subscription/cancelorder/${id}`, {
     
      })
      .then(({data}) => {

   //  console.log(data)
     dispatch({
      type:  CANCEL_ORDER_SUCCESS,
      payload: {
       
        data,
      },
    });
      })
      .catch(err => {
       console.log(err.response);
        dispatch({ type: CANCEL_ORDER_FAILURE });
      });
  };

  export const checkPayments = () => (dispatch, getState) => {
    dispatch({ type: CHECK_PAYMENTS_REQUEST });
  
    OptionsAuth(getState().token);
   
 
    return axios
      .get(`${url}/api/v1/subscription/checkpayments`, {
     
      })
      .then(({data}) => {

     console.log(data)
   

   
      })
      .catch(err => {
       console.log(err.response);
        dispatch({ type: CHECK_PAYMENTS_FAILURE });
      });
  };


  export const fetchInvoices = (id) => (dispatch, getState) => {
    dispatch({ type: FETCH_INVOICES_REQUEST });
  
    OptionsAuth(getState().token);
   
 
    return axios
      .get(`${url}/api/v1/subscription/fetchinvoices/${id}`, {
     
      })
      .then(({data}) => {

     // console.log(data)
     dispatch({
      type:  FETCH_INVOICES_SUCCESS,
      payload: {
       
        data,
      },
    });
   

   
      })
      .catch(err => {
       console.log(err.response);
        dispatch({ type: FETCH_INVOICES_FAILURE });
      });
  };