import React, {Component}   from 'react'
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { Redirect, NavLink } from 'react-router-dom';
import {routes} from 'routes';
import Hr from 'components/atoms/Hr/Hr';
import SmallSpinner from 'components/atoms/Spinner/SmallSpinner';
import correctIcon from 'assets/icons/correct.svg';


import Paragraph from 'components/atoms/Paragraph/Paragraph';
import Heading from 'components/atoms/Heading/Heading';
import Button from 'components/atoms/Button/Button';
import Input from 'components/atoms/Input/Input';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import ListMenu from 'components/atoms/ListMenu/ListMenu';
import Item from 'components/atoms/ListMenu/Item';
import Image from 'components/atoms/Image/Image';
import PayuEN from 'assets/icons/payu_en.png';
import PayuPL from 'assets/icons/payu_pl.png';
import Paypal from 'assets/icons/paypal.svg';

import { fetchSubscriptions as fetchSubscriptionsAction, newOrderPayu as newOrderPayuAction} from 'actions/subscription';
import { getCompanyInfo as getCompanyInfoAction } from 'actions/company';

import {clearMessage as clearMessageAction} from 'actions';
import { connect } from 'react-redux';
import withContext from 'hoc/withContext';
import gsap from "gsap";


import Card from 'components/molecules/Card/Card';
import PayuCard from 'components/molecules/PaymentsCard/PayuCard';
import PaypalCard from 'components/molecules/PaymentsCard/PaypalCard';

import HeaderWrapper from 'components/atoms/HeaderWrapper/HeaderWrapper';
import InnerWrapper from 'components/atoms/InnerWrapper/InnerWrapper';
import Buttons from 'components/atoms/Buttons/Buttons';
import {Payu} from 'helpers/payu';



const StyledWrapper = styled.div`
  display:flex;
  flex-direction:row;
  ${({ theme }) => theme.mq.standard} {
   
   flex-direction:column-reverse;
   max-width:700px;
 }

 ${({ theme }) => theme.mq.tablet} {
   max-width:700px;
 }
 ${({ theme }) => theme.mq.mobile} {
   max-width:300px;
 }
`;

const StyledListMenu=styled(ListMenu)`
  display:flex;
  padding:0;
  align-items:flex-start;
`;

const StyledItem=styled(Item)`
  display:flex;

`;

const StyledButtons=styled(Buttons)`
  justify-content:center;
`;


const StyledSvg=styled.div`
    width:27px;
    height:27px;
    background-image:url(${({icon}) => icon});
    background-repeat:no-repeat;
    background-position:50% 50%;
    background-size:60%;
    margin-right:10px;
    
`;

const StyledPayuButton= styled(Button)`
width:300px;
background-repeat:no-repeat;
background-size:100%;
    background-position:50% 50%;
  background-image:url(${({lang}) => lang=='en' ? PayuEN : PayuPL});
  margin-bottom:10px;
`;

const StyledPaypalButton=styled(Button)`
background-repeat:no-repeat;
background-size:30%;
    background-position:50% 50%;
  background-image:url(${Paypal});
  background-color:#FFC439;
`;

const StyledInnerWrapper= styled(InnerWrapper)`
   
    align-items:center;
    max-height:100%;
    
`;




const StyledCardInnerWrapper= styled(InnerWrapper)`
   
  display:flex;
  flex-direction:row;
  
`;


const StyledCard= styled(Card)`
max-width:100%;
  position:absolute;
`;


const StyledCardSubscriptions= styled(Card)`
  max-width:300px;
  margin-right:10px;
`;

class Subscriptions extends Component {
    constructor(){
        super();
        this.ShowUserInfoWrap = null;
        this.FormAvatarWrap = null;
        this.FormPayu=null;
        this.FormPaypal=null;
        this.tlUserInfo = null;
        this.tlAvatarForm = null;
        
        this.PayU=window.PayU;
        this.payuSdkForms = this.PayU('395407',1);
        this.secureForms = this.payuSdkForms.secureForms();
        this.cardNumber = this.secureForms.add('number');
        this.cardDate = this.secureForms.add('date');
        this.cardCvv = this.secureForms.add('cvv');

      //  this.paypalClientid="AbSrA3zgGFLiLXnF7a19OpSAtVrkiX1C-iJrwdDDJ26KXH2eGODAxZJqBUNFwXfAdGyreA336c7BQsfE";


       
    }
  state={
    price:null,
    subid:null,
    lang:null,
    type:null,
    name:null,
    paypalPlanid:null,
   
  
  }

  loadScript(src) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script");

        script.onload = resolve;
        script.onerror = reject;

        script.src = src;

        document.head.appendChild(script);
        
    });
  }


    componentDidMount(){

        const { id,fetchSubscriptions, getCompanyInfo, newOrderPayu } = this.props;
       

        getCompanyInfo(id);
        fetchSubscriptions();

     
     
    
        this.tlUserInfo = gsap.timeline({defaults:{ease:'power3.inOut'}})
        .set([this.ShowUserInfoWrap,  this.FormAvatarWrap, this.FormPayu,  this.FormPaypal], {autoAlpha: 0})
        .fromTo(this.ShowUserInfoWrap, { y: "-100"}, {delay:1,duration: 1, y:"+=100", autoAlpha:1})

        this.loadScript('https://secure.payu.com/javascript/sdk')
        .then(() => {
            // utworzenie instancji JS SDK…
          
        
            this.cardNumber.render('#payu-card-number');
            this.cardDate.render('#payu-card-date');
            this.cardCvv.render('#payu-card-cvv');
  
          
  
        })
        .catch((e) => console.log(e) );

      
    }

    

    togglePackage(){
    
     
      
      this.tlAvatarForm = gsap.timeline({defaults:{ease:'power3.inOut'}})
      .to(this.ShowUserInfoWrap,  {duration: 1, y:"-=100", autoAlpha:0})
      .fromTo(this.FormAvatarWrap, { y: "-100px"}, {duration: 0.5, y:"+=100px", autoAlpha:1})
      
    }

    hidePackage(){
     
      this.cardCvv.clear();
      this.cardDate.clear();
      this.cardNumber.clear();
      
      this.tlAvatarForm
      .to(this.FormAvatarWrap, {duration: 0.4,  autoAlpha:0})
      .to(this.FormPayu, {duration: 0.4,  autoAlpha:0},'-=0.4')
      .to(this.FormPaypal, {duration: 0.4,  autoAlpha:0},'-=0.4')
      .fromTo(this.ShowUserInfoWrap, { y: "-100px"}, {duration: 0.5, y:"+=100px", autoAlpha:1})
    }

    showPayuCard(){
   
    

      this.tlAvatarForm = gsap.timeline({defaults:{ease:'power3.inOut'}})
      .to(this.FormPaypal, {y: "-300px",duration: 0.4,  autoAlpha:0},'-=0.4')
      .fromTo(this.FormPayu, { y: "-100px"}, {duration: 0.5, y:"+=100px", autoAlpha:1})
    }

    showPaypalCard(){
   
      this.tlAvatarForm = gsap.timeline({defaults:{ease:'power3.inOut'}})
      .to(this.FormPayu, {duration: 0.4,  autoAlpha:0},'-=0.4')
      .fromTo(this.FormPaypal, { y: "-100px"}, {duration: 0.5, y:"+=100px", autoAlpha:1})
    }

    newOrder =()=>{
      
      const {subid,price,type,lang}=this.state;
      
      Payu(this.payuSdkForms,subid,price,type,lang);
    }

  

 

    handleSubscriptionType = (name,id,price,type,lang,paypalplanid) =>{
      this.togglePackage();
      this.setState({
        name:name,
        subid:id,
        price:price,
        type:type,
        lang:lang,
        paypalPlanid:paypalplanid
      });
    }
   

   

    render() {
        
      const {id, pageContext,authuserinfo, subscriptions, message, events, clearMessage, companyinfo, isLoadingContent, alert} =this.props;
      const {price, name, type, paypalPlanid, subid}=this.state;
    
    
      const roleAdmin = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='SuperAdmin').length;
      const roleCustomer = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='Customer').length && companyinfo.id==authuserinfo.company_id;
      const accesscompany=companyinfo && companyinfo.access && companyinfo.access.length && companyinfo.access[0].name.name;
      const renew=companyinfo && companyinfo.access && companyinfo.access.length && companyinfo.access[0].payment && companyinfo.access[0].payment.length 
      && companyinfo.access[0].payment[0].renew;

        return (
        <StyledWrapper >
      
         

            <StyledCard ref={div => this.ShowUserInfoWrap = div} pagecolor={pageContext.pageColor}>
            <HeaderWrapper  activeColor={pageContext.sidebarColor}>
                    <Heading>{pageContext.t(`card.subscriptions`)}</Heading>
                    
                
                </HeaderWrapper>
                <StyledCardInnerWrapper  pagecolor={pageContext.pageColor}>
            { (roleAdmin || roleCustomer) && (accesscompany!='Full' && renew==0) ?
            <>
              {subscriptions.map(sub=>
            
              <StyledCardSubscriptions pagecolor={pageContext.pageColor}>
                  <HeaderWrapper  activeColor={sub.color}>
                  <Heading>{sub.name}</Heading>

                  </HeaderWrapper>
 
                    <StyledInnerWrapper  pagecolor={pageContext.pageColor}>

                      <Paragraph medium>{sub.month_price}  PLN {pageContext.t(`for`)} 1 {pageContext.t(`month`)}</Paragraph>
                      <Paragraph medium>{pageContext.t(`or`)}</Paragraph>
                      <Paragraph medium>{sub.year_price}  PLN/{pageContext.t(`month`)} {pageContext.t(`for`)} 12 {pageContext.t(`months`)} </Paragraph>

                    </StyledInnerWrapper>
                      <Hr/>
                    <StyledInnerWrapper  pagecolor={pageContext.pageColor}>
                  
                    <StyledListMenu pagecolor={pageContext.pageColor}>
                      <StyledItem><StyledSvg icon={correctIcon} />{pageContext.t(`maximum`)} {sub.number_of_users} {pageContext.t(`employees`)}</StyledItem>
                      <StyledItem><StyledSvg icon={correctIcon} />{pageContext.t(`unlimited_events`)}</StyledItem>
                      
                    </StyledListMenu>
          
            
                    </StyledInnerWrapper>

                    <StyledButtons pagecolor={pageContext.pageColor} activeColor={pageContext.sidebarColor}>
                    
                   

                    <Button onClick={()=>this.handleSubscriptionType(sub.name,sub.id, sub.month_price,"monthly", pageContext.t('lang'),sub.paypal_plan_monthly)}  small activecolor={pageContext.sidebarColor} >
                     1 {pageContext.t(`month`)}
                    </Button>

                    <Button onClick={()=>this.handleSubscriptionType(sub.name,sub.id, Math.round(sub.year_price*12 * 100) / 100,"yearly", pageContext.t('lang'),sub.paypal_plan_yearly)}  small activecolor={pageContext.sidebarColor} >
                     12 {pageContext.t(`months`)}
                    </Button>

                   

    
                  
                    </StyledButtons>

              </StyledCardSubscriptions>
              )}
              </>
            : ''}
            </StyledCardInnerWrapper>
              <StyledButtons pagecolor={pageContext.pageColor} activeColor={pageContext.sidebarColor}>
                <Button type="button"
                        as={NavLink} to={`/settings/company/${id}/invoices`} medium back >
                        {pageContext.t('button.back')}
                </Button>

              </StyledButtons>
            </StyledCard>

            <StyledCard pagecolor={pageContext.pageColor} ref={div => this.FormAvatarWrap = div}>
                  <HeaderWrapper  activeColor={pageContext.sidebarColor}>
                  <Heading>{pageContext.t(`chosen_package`)}: {name}</Heading>
                  
                  
                  </HeaderWrapper>
                    <StyledInnerWrapper>

                    <Heading medium>{pageContext.t(`chosen_package`)}</Heading>
                    <Paragraph medium>{name}</Paragraph>

                    <Heading medium>{pageContext.t('statistics.payment_method')}</Heading>
                    <Paragraph medium>{type}</Paragraph>

                    <Heading medium>{pageContext.t(`amount_charged`)}</Heading>
                    <Paragraph medium>{price} PLN</Paragraph>


                    <StyledPayuButton onClick={()=>this.showPayuCard()} lang={pageContext.t('lang')} medium back/>
                        
                   

                    <StyledPaypalButton onClick={()=>this.showPaypalCard()} medium ></StyledPaypalButton>
            
                    </StyledInnerWrapper>
                    
                    <StyledButtons pagecolor={pageContext.pageColor} activeColor={pageContext.sidebarColor}>
                <Button onClick={()=>this.hidePackage()} medium back>
                        {pageContext.t('button.back')}
                </Button>

              </StyledButtons>
               
                
            </StyledCard>
            
           <div ref={div => this.FormPayu = div}>
            <PayuCard newOrder={this.newOrder} pageContext={pageContext}  /> 
            </div>  
            <div ref={div => this.FormPaypal = div}>
            <PaypalCard type={type} subid={subid} company={companyinfo} user={authuserinfo} planid={paypalPlanid} price={price} pageContext={pageContext}  /> 
             
            </div>  
        
        </StyledWrapper>
        )
    }
  
}
    

const mapStateToProps = state => {

    const { alert, message,  authuserinfo, companyinfo, isLoadingContent, subscriptions} = state;
  
    return { alert, message,  authuserinfo, companyinfo, isLoadingContent, subscriptions};
  };
  
  const mapDispatchToProps = dispatch => ({
   
    getCompanyInfo: (id) =>dispatch(getCompanyInfoAction(id)),
    fetchSubscriptions: () =>dispatch(fetchSubscriptionsAction()),
    newOrderPayu: (tok) =>dispatch(newOrderPayuAction(tok)),
    clearMessage: () =>dispatch(clearMessageAction())
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withContext(Subscriptions));