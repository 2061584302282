import React from 'react';
import { PayPalButton } from "react-paypal-button-v2";
import {url, OptionsAuth} from 'actions'
import axios from 'axios';
import Card from 'components/molecules/Card/Card';
import HeaderWrapper from 'components/atoms/HeaderWrapper/HeaderWrapper';
import InnerWrapper from 'components/atoms/InnerWrapper/InnerWrapper';
import Heading from 'components/atoms/Heading/Heading';
import moment from 'moment';

import styled, { css } from 'styled-components';




const StyledInnerWrapper= styled(InnerWrapper)`
   
    align-items:center;
    max-height:100%;
    
`;


const StyledPayuCard=styled(Card)`

width: 420px;
position:absolute;

margin-left:350px;
`;




const PaypalCard = ({subid,type,company,user,price,planid,pageContext})=>{
    const us=user && user.name;
    const firstname=us &&  us.substr( 0, us && us.indexOf( " "));
    const lastname=us && us.substr( us && us.indexOf( " "));
    const paypalKey = "AbSrA3zgGFLiLXnF7a19OpSAtVrkiX1C-iJrwdDDJ26KXH2eGODAxZJqBUNFwXfAdGyreA336c7BQsfE";
  const starttime=moment(Date.now()).format("YYYY-MM-DD[T]HH:mm:ss[Z]");

    const paypalSubscribe = (data, actions) => {
        return actions.subscription.create({
        
            "plan_id": planid,
            "quantity": "1",
            "start_time": starttime,
            "shipping_amount": {
              "currency_code": "PLN",
              "value": price
            },
            "subscriber": {
              "name": {
                "given_name": firstname,
                "surname": lastname
              },
              "email_address": user.email,
              "shipping_address": {
                "name": {
                  "full_name": firstname+" "+lastname
                },
                "address": {
                    "address_line_1": company.address,
                    "address_line_2": company.address_building_number,
                    "admin_area_2": company.city,
                    "postal_code": company.postcode,
                    "country_code": "PL"
                }
              }
            },
            "application_context": {
              "brand_name": "Calendar Intervi",
              "locale": "pl-PL",
              "shipping_preference": "NO_SHIPPING",
              "user_action": "SUBSCRIBE_NOW",
              "payment_method": {
                "payer_selected": "PAYPAL",
                "payee_preferred": "IMMEDIATE_PAYMENT_REQUIRED"
              },
              "return_url": "https://example.com/returnUrl",
              "cancel_url": "https://example.com/cancelUrl"
            }
        });
        };

    const paypalOnError = (err) => {
            console.log("Error")
    }

    const paypalOnApprove = (data, detail) => {
                // call the backend api to store transaction details
               // console.log("Payapl approved")
              //  console.log(data.subscriptionID)
                
                OptionsAuth(localStorage.getItem('token'));
                axios
                 .post(`${url}/api/v1/subscription/paypal/new_order`, {
                paypalsubid:data.subscriptionID,
               
                price:price,
                type:type,
                id:subid,
               
                 })
                 .then(({data}) => {
           
               console.log(data)
               // Simulate an HTTP redirect:
                  window.location.replace(data.data);
                 })
                 .catch(err => {
                  console.log(err.response);
                  
                 });

    };

return (
    <StyledPayuCard pagecolor={pageContext.pageColor}>
        <HeaderWrapper  activeColor={({theme})=>theme.paypal}>
            <Heading>{pageContext.t(`card.confirm_payment`)}</Heading>
                  
                  
        </HeaderWrapper>

        <StyledInnerWrapper>
      
        
        <PayPalButton
        amount={price}
        currency="PLN"
        createSubscription={(data, details) => paypalSubscribe(data, details)}
        onApprove={(data, details) => paypalOnApprove(data, details)}
        onError={(err) => paypalOnError(err)}
        catchError={(err) => paypalOnError(err)}
        onCancel={(err) => paypalOnError(err)}
        options={{
        clientId: paypalKey,
        vault:true
        }}

        />
           

         
       
        </StyledInnerWrapper>
    </StyledPayuCard>
)
}

export default PaypalCard;
