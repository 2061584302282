import React from 'react';
import Buttons from 'components/atoms/Buttons/Buttons';
import Button from 'components/atoms/Button/Button';
import Paragraph from 'components/atoms/Paragraph/Paragraph';
import Card from 'components/molecules/Card/Card';
import HeaderWrapper from 'components/atoms/HeaderWrapper/HeaderWrapper';
import InnerWrapper from 'components/atoms/InnerWrapper/InnerWrapper';
import Heading from 'components/atoms/Heading/Heading';
import Checkboxdefault from 'components/atoms/Input/Checkboxdefault'
import styled, { css } from 'styled-components';

const StyledPayuButtons=styled(Buttons)`
  justify-content:center;
  border-top:0px;
`;


const StyledInnerWrapper= styled(InnerWrapper)`
   
    align-items:center;
    max-height:100%;
    
`;


const StyledPayuCard=styled(Card)`

position:absolute;
margin-left:350px;
`

const CardCointainer = styled.div`
	width: 100%;
	margin: 0 auto;
	border-radius: 6px;
	padding: 10px;
	background-color: ${({ activeColor}) => activeColor};
	text-align: left;
	box-sizing: border-box;
`;
const StyledWrapper = styled.div`
    	width: 420px;
`;

const StyledInput =styled.div`
	background-color: #ffffff;
	padding: 10px;
	border-radius: 4px;
`;

const CardDetails = styled.div`
	clear: both;
	overflow: auto;
	margin-top: 10px;
`;

const Expiration = styled.div`
	width: 50%;
	float: left;
	padding-right: 5%;
`;

const Cvv = styled.div`
	width: 45%;
	float: left;
`;

const StyledInfo=styled.div`
display:flex;
flex-direction:row;
`;


const PayuCard = ({pageContext,newOrder})=>{
return (
    <StyledPayuCard pagecolor={pageContext.pageColor}>
        <HeaderWrapper  activeColor={({theme})=>theme.payu}>
            <Heading>{pageContext.t(`card.confirm_payment`)}</Heading>
                  
                  
        </HeaderWrapper>

        <StyledInnerWrapper>
        <StyledWrapper>
        <div id="responseTokenize"></div>
        <CardCointainer activeColor={({theme})=>theme.payu}>
        
            <Paragraph>{pageContext.t(`card_number`)}</Paragraph>
            <StyledInput class="payu-card-form" id="payu-card-number"></StyledInput>

            <CardDetails>
                <Expiration>
                <Paragraph>{pageContext.t(`valid to`)}</Paragraph>
                    <StyledInput class="payu-card-form" id="payu-card-date"></StyledInput>
                </Expiration>

                <Cvv>
                <Paragraph>CVV</Paragraph>
                    <StyledInput class="payu-card-form" id="payu-card-cvv"></StyledInput>
                </Cvv>
            </CardDetails>
        </CardCointainer>
        
        <StyledInfo><Checkboxdefault/><Paragraph activecolor={({theme})=>theme.payu}>{pageContext.t(`accepting`)} <a href="https://static.payu.com/sites/terms/files/payu_terms_of_service_single_transaction_pl_pl.pdf" target="_blank">{pageContext.t(`terms_single_payu_payment`)}</a></Paragraph></StyledInfo>
        <Paragraph activecolor={({theme})=>theme.payu} small>{pageContext.t(`saving_card_details`)} <a href="https://static.payu.com/sites/terms/files/REGULAMIN_SWIADCZENIA_USLUGI_PAYU.pdf" target="_blank">{pageContext.t(`regulations`)}</a> {pageContext.t(`wantto_use_for_future_payments`)}</Paragraph>
       
        <StyledPayuButtons pagecolor={pageContext.pageColor} activeColor={pageContext.sidebarColor}>
        <Button medium activecolor={({theme})=>theme.payu} id="tokenizeButton" onClick={()=>newOrder()} >{pageContext.t(`button.pay`)}</Button>
        </StyledPayuButtons>
        
        </StyledWrapper>
    </StyledInnerWrapper>
    </StyledPayuCard>
)
}

export default PayuCard;
