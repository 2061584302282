import React, { Component }  from 'react'
import Settings from 'views/Settings';

import EditAvatar from 'components/organisms/EditPerson/EditAvatar';



const Avatar = () => {



    return (
    <Settings>
       <EditAvatar/>
    </Settings>
    )


}


export default Avatar;