import React  from 'react'
import Settings from 'views/Settings';

import Subscription from 'components/organisms/Company/Subscriptions';



const Subscriptions =({match}) =>{
        
    
    return (
    <Settings>
       <Subscription  id={match.params.id}/>
    </Settings>
    )


}



export default Subscriptions;