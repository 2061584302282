import React  from 'react'
import Settings from 'views/Settings';

import PaymentDetails  from 'components/organisms/Company/Invoices/PaymentDetails';



const Details =({match}) =>{
        
   

    
    return (
    <Settings>
       <PaymentDetails  id={match.params.id} payment={match.params.payment}/>
    </Settings>
    )


}



export default Details;