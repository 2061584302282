import React from 'react'
import Settings from 'views/Settings';

import EventsLogs from 'components/organisms/EditUsers/EventsLogs';



const UserEventsLogs = ({match}) => {



    return (
    <Settings>
       
      <EventsLogs id={match.params.id}/>
     

   
    </Settings>
    )


}



export default UserEventsLogs;