import React from 'react'
import Settings from 'views/Settings';

import EventsForm from 'components/organisms/EditUsers/EventsForm';



const UserEventsEdit = ({match}) => {



    return (
    <Settings>
       
      <EventsForm id={match.params.id}/>
     

   
    </Settings>
    )


}



export default UserEventsEdit;