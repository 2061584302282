import React, {Component}   from 'react'
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { Redirect, NavLink } from 'react-router-dom';
import {routes} from 'routes';
import Paragraph from 'components/atoms/Paragraph/Paragraph';
import Heading from 'components/atoms/Heading/Heading';
import Button from 'components/atoms/Button/Button';
import Input from 'components/atoms/Input/Input';
import Select from 'components/atoms/Input/Select';
import QuestionnaireMenu from 'components/molecules/Menus/QuestionnaireMenu';
import Card from 'components/molecules/Card/Card';
import HeaderWrapper from 'components/atoms/HeaderWrapper/HeaderWrapper';
import InnerWrapper from 'components/atoms/InnerWrapper/InnerWrapper';
import Buttons from 'components/atoms/Buttons/Buttons';
import Radio from 'components/atoms/Input/RadioDisability';

import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import { getUserInfo as getUserInfoAction, editQuestionnaire as editQuestionnaireAction } from 'actions/user';
import {clearMessage as clearMessageAction} from 'actions';
import { connect } from 'react-redux';
import withContext from 'hoc/withContext';
import gsap from "gsap";
import {alerts} from 'helpers/alerts';

import * as Yup from 'yup'; 


const StyledWrapper = styled.div`
  display:flex;
`;

const StyledHeading = styled(Heading)`
  text-align:center;
  font-weight:bold;
`;

const StyledInnerWrapper = styled(InnerWrapper)`
  flex-direction:row;
`;

const StyledInputWrapper = styled.div`
  flex-direction:column;
  padding:5px;
 
`;

const StyledCard=styled(Card)`
  max-width:100%;
`;

const StyledInput=styled(Input)`
  width:150px;
`;
  

class AddUserForm extends Component {

  constructor(){
    super();
    this.ShowUserInfoWrap = null;
    this.FormUserInfoWrap = null;
  
    this.tlUserInfo = null;
    this.FormUserInfoWrap= null;
   
}

state={
  redirect:null,
  filter:'brak',
}

  componentDidUpdate(prevProps, prevState){
    const {alert, getuserinfo} = this.props;

    const questionnaire = getuserinfo && getuserinfo.questionnaire && getuserinfo.questionnaire[0] && getuserinfo.questionnaire[0].disability ;

    if( prevState.filter =='brak' && questionnaire!='brak' )
    {   
       
        if(questionnaire)
        {
        this.setState({ 
            filter:questionnaire 
          });
        }
      //  console.log(questionnaire)
    }

    
  }

  componentDidMount(){

    const { id,getUserInfo, clearMessage } = this.props;
    clearMessage();
    getUserInfo(id);
   
    
   

    this.tlUserInfo = gsap.timeline({defaults:{ease:'power3.inOut'}})
        .set([this.FormUserInfoWrap], {autoAlpha: 0})
       .fromTo(this.FormUserInfoWrap, { y: "-100"}, {duration: 1, y:"+=100", autoAlpha:1})
   
    
    }

    addToFilter = (data) =>{
      this.setState ({
          filter: data
        });
     
    }

    handleChangeValue=(data)=>{
    
      this.setState({ [data.target.name]: data.target.value});
    }

    render() {
       
        const { id,pageContext, message, authuserinfo,alert, clearMessage, getuserinfo, editQuestionnaire} =this.props;
        const {
          redirect, filter, first_name, second_name, name,family_name, PESEL, fathers_name,mothers_name,NIP,tax_office,
          citizenship,number_of_proof,date_of_birth,place_of_birth,education,experience,phone_number,country,county,
          province,community,address,house_number,post_code,city,insurance,bank_name,account_number,student,company
        
        }=this.state;

        const permission = authuserinfo && authuserinfo.workplace && authuserinfo.workplace.permissions  && authuserinfo.workplace.permissions.filter(perm=>perm.name=='Users').length;
        const roleAdmin = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='SuperAdmin').length;
        const roleCustomer = authuserinfo && authuserinfo.roles && authuserinfo.roles.filter(role=>role.name=='Customer').length;

        const questionnaire = getuserinfo && getuserinfo.questionnaire && getuserinfo.questionnaire[0] ;

    
       
     
        if(redirect)
        {
          return <Redirect to={redirect} />;
        }

        return (
          

         
            <StyledWrapper ref={div => this.FormUserInfoWrap = div}>
            {permission || roleAdmin || roleCustomer ?
            <>
            <StyledCard pagecolor={pageContext.pageColor}>
            <HeaderWrapper  activeColor={pageContext.sidebarColor}>
              <Heading>{pageContext.t('button.personalquestionnaire')}: {getuserinfo.name}</Heading>
              
             
            </HeaderWrapper>
            <Formik enableReinitialize
            initialValues={{ first_name:first_name ? first_name: questionnaire && questionnaire.first_name, second_name: second_name ? second_name : questionnaire && questionnaire.second_name, name: name ? name : questionnaire && questionnaire.name,
            family_name:family_name ? family_name : questionnaire && questionnaire.family_name, PESEL:PESEL ? PESEL: questionnaire  && questionnaire.PESEL, fathers_name: fathers_name ? fathers_name : questionnaire && questionnaire.fathers_name,
            mothers_name: mothers_name ? mothers_name : questionnaire && questionnaire.mothers_name, NIP: NIP ? NIP : questionnaire && questionnaire.NIP, tax_office : tax_office ? tax_office : questionnaire && questionnaire.tax_office, citizenship: citizenship ? citizenship : questionnaire && questionnaire.citizenship,
            number_of_proof:number_of_proof ? number_of_proof : questionnaire && questionnaire.number_of_proof,date_of_birth: date_of_birth ? date_of_birth : questionnaire && questionnaire.date_of_birth, place_of_birth: place_of_birth ? place_of_birth : questionnaire && questionnaire.place_of_birth,
            education: education ? education : questionnaire && questionnaire.education, experience:experience ? experience :questionnaire && questionnaire.experience, phone_number: phone_number ? phone_number : questionnaire && questionnaire.phone_number,
            country:country ? country : questionnaire && questionnaire.country,province:province? province : questionnaire && questionnaire.province,county: county ? county : questionnaire && questionnaire.county, community: community? community : questionnaire && questionnaire.community,
            address: address ? address : questionnaire && questionnaire.address, house_number: house_number ? house_number : questionnaire && questionnaire.house_number,post_code: post_code? post_code : questionnaire && questionnaire.post_code, city : city ? city : questionnaire && questionnaire.city,
            disability : filter, student: student ? student : questionnaire && questionnaire.student, insurance : insurance ? insurance : questionnaire && questionnaire.insurance, 
            company : company ? company : questionnaire && questionnaire.company,
            bank_name : bank_name ? bank_name : questionnaire && questionnaire.bank_name,account_number:  account_number ? account_number :  questionnaire && questionnaire.account_number
             }}
           
    
            onSubmit={values => {
            
           editQuestionnaire(values,id);

             
            }}
      
            >  
            {({ values, handleChange, handleBlur,errors, touched,setFieldValue }) => (
              <Form>
              <InnerWrapper  pagecolor={pageContext.pageColor}>
             
              {alert ? alerts(pageContext,'alerts.',message,'success') : ''}

              <StyledHeading medium>Dane osobowe</StyledHeading>

              <StyledInnerWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Pierwsze imię</Heading>
                      <StyledInput card
                      type="text"
                      name="first_name"
                      placeholder={values.firstname}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.first_name} 
                    
                      />
                  </StyledInputWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Drugie imie</Heading>
                      <StyledInput card
                      type="text"
                      name="second_name"
                      placeholder={values.email}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.second_name}
                    
                      />
                  </StyledInputWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Nazwisko</Heading>
                      <StyledInput card
                      type="text"
                      name="name"
                      placeholder={values.email}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.name}
                    
                      />
                  </StyledInputWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Nazwisko rodowe</Heading>
                      <StyledInput card
                      type="text"
                      name="family_name"
                      placeholder={values.email}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.family_name}
                    
                      />
                  </StyledInputWrapper>

                </StyledInnerWrapper>

                <StyledInnerWrapper>

                  <StyledInputWrapper>
                    <Heading medium>PESEL</Heading>
                      <StyledInput card
                      type="text"
                      name="PESEL"
                      placeholder={values.firstname}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.PESEL} 
                    
                      />
                  </StyledInputWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Imię ojca</Heading>
                      <StyledInput card
                      type="text"
                      name="fathers_name"
                      placeholder={values.email}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.fathers_name}
                    
                      />
                  </StyledInputWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Imię matki</Heading>
                      <StyledInput card
                      type="text"
                      name="mothers_name"
                      placeholder={values.email}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.mothers_name}
                    
                      />
                  </StyledInputWrapper>

                  <StyledInputWrapper>
                    <Heading medium>NIP</Heading>
                      <StyledInput card
                      type="text"
                      name="NIP"
                      placeholder={values.email}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.NIP}
                    
                      />
                  </StyledInputWrapper>

                </StyledInnerWrapper>


                <StyledInnerWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Urząd skarbowy</Heading>
                      <StyledInput card
                      type="text"
                      name="tax_office"
                      placeholder={values.firstname}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.tax_office} 
                    
                      />
                  </StyledInputWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Obywatelstwo</Heading>
                      <StyledInput card
                      type="text"
                      name="citizenship"
                      placeholder={values.email}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.citizenship}
                    
                      />
                  </StyledInputWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Seria i numer dowodu</Heading>
                      <StyledInput card
                      type="text"
                      name="number_of_proof"
                      placeholder={values.email}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.number_of_proof}
                    
                      />
                  </StyledInputWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Data urodzenia</Heading>
                      <StyledInput card
                      type="text"
                      name="date_of_birth"
                      placeholder={values.email}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.date_of_birth}
                    
                      />
                  </StyledInputWrapper>

                </StyledInnerWrapper>

                  <StyledInnerWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Miejsce urodzenia</Heading>
                      <StyledInput card
                      type="text"
                      name="place_of_birth"
                      placeholder={values.firstname}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.place_of_birth} 
                    
                      />
                  </StyledInputWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Wykształcenie</Heading>
                      <StyledInput card
                      type="text"
                      name="education"
                      placeholder={values.email}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.education}
                    
                      />
                  </StyledInputWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Doświadczenie</Heading>
                      <StyledInput card
                      type="text"
                      name="experience"
                      placeholder={values.email}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.experience}
                    
                      />
                  </StyledInputWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Telefon</Heading>
                      <StyledInput card
                      type="text"
                      name="phone_number"
                      placeholder={values.email}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.phone_number}
                    
                      />
                  </StyledInputWrapper>

                </StyledInnerWrapper>   

                <StyledHeading medium>Adres zamieszkania</StyledHeading>  

                <StyledInnerWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Kraj</Heading>
                      <StyledInput card
                      type="text"
                      name="country"
                      placeholder={values.firstname}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.country} 
                    
                      />
                  </StyledInputWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Województwo</Heading>
                      <StyledInput card
                      type="text"
                      name="province"
                      placeholder={values.email}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.province}
                    
                      />
                  </StyledInputWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Powiat</Heading>
                      <StyledInput card
                      type="text"
                      name="county"
                      placeholder={values.email}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.county}
                    
                      />
                  </StyledInputWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Gmina</Heading>
                      <StyledInput card
                      type="text"
                      name="community"
                      placeholder={values.email}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.community}
                    
                      />
                  </StyledInputWrapper>

                </StyledInnerWrapper>   

                <StyledInnerWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Ulica/miejscowość</Heading>
                      <StyledInput card
                      type="text"
                      name="address"
                      placeholder={values.firstname}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.address} 
                    
                      />
                  </StyledInputWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Nr domu/nr lokalu</Heading>
                      <StyledInput card
                      type="text"
                      name="house_number"
                      placeholder={values.email}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.house_number}
                    
                      />
                  </StyledInputWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Kod pocztowy</Heading>
                      <StyledInput card
                      type="text"
                      name="post_code"
                      placeholder={values.email}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.post_code}
                    
                      />
                  </StyledInputWrapper>

                  <StyledInputWrapper>
                    <Heading medium>Miasto</Heading>
                      <StyledInput card
                      type="text"
                      name="city"
                      placeholder={values.email}
                      onChange={this.handleChangeValue}
                      onBlur={handleBlur}
                      value={values.city}
                    
                      />
                  </StyledInputWrapper>

                </StyledInnerWrapper>   

               
                <StyledHeading medium>Pozostałe</StyledHeading>  
                 <StyledInnerWrapper>
                    <StyledInputWrapper>
                    
                    <Heading medium>Posiadam orzeczenie o niepełnosprawności:</Heading>

                        <Field
                        type="radio"
                        name='disability'
                        id="brak"
                        label="brak"
                        setFieldValue={setFieldValue}
                        component={Radio}
                        onChange={this.handleChangeValue}
                        onBlur={handleBlur}
                        filter={filter}
                        addToFilter={this.addToFilter}
                        />

                        <Field
                        type="radio"
                        name='disability'
                        id="W stopniu znacznym"
                        label="W stopniu znacznym"
                        setFieldValue={setFieldValue}
                        component={Radio}
                        onChange={this.handleChangeValue}
                        onBlur={handleBlur}
                        filter={filter}
                        addToFilter={this.addToFilter}
                        />

                        <Field
                        type="radio"
                        name='disability'
                        id="Umiarkowanym"
                        label="Umiarkowanym"
                        setFieldValue={setFieldValue}
                        component={Radio}
                        onChange={this.handleChangeValue}
                        onBlur={handleBlur}
                        filter={filter}
                        addToFilter={this.addToFilter}
                        />

                        <Field
                        type="radio"
                        name='disability'
                        id="Lekkim"
                        label="Lekkim"
                        setFieldValue={setFieldValue}
                        component={Radio}
                        onChange={this.handleChangeValue}
                        onBlur={handleBlur}
                        filter={filter}
                        addToFilter={this.addToFilter}
                        />
                      </StyledInputWrapper>

                      <StyledInputWrapper>
                    
                      <Heading medium>Jestem uczniem/studentem:</Heading>
                          <Select  
                          name="student" 
                          onChange={this.handleChangeValue}
                      
                          onBlur={handleBlur}
                          
                          >
                         <option key="tak" selected={values.student==1 ? "selected" : ''}  value="1">Tak</option>
                         <option key="nie" selected={values.student==0 ? "selected" : ''} value="0">Nie</option>
                          </Select>
                      </StyledInputWrapper>

                  </StyledInnerWrapper>   

                  <StyledInnerWrapper>

                    <StyledInputWrapper>
                    
                    <Heading medium>Wnoszę o objęcie dobrowolnym ubezp. społ:</Heading>
                      <Input card
                        type="text"
                        name="insurance"
                        placeholder={values.email}
                        onChange={this.handleChangeValue}
                        onBlur={handleBlur}
                        value={values.insurance}
                      
                        />
                    </StyledInputWrapper>

                    <StyledInputWrapper>
                    
                    <Heading medium>Prowadzę działalność gospodarczą:</Heading>
                        <Select  
                        name="company" 
                        onChange={this.handleChangeValue}
                    
                        onBlur={handleBlur}
                        
                        >
                        <option key="tak" selected={values.company==1 ? "selected" : ''}  value="1">Tak</option>
                         <option key="nie" selected={values.company==0 ? "selected" : ''} value="0">Nie</option>
                        
                        </Select>
                    </StyledInputWrapper>

                  </StyledInnerWrapper>

                  <StyledInnerWrapper>

                    <StyledInputWrapper>

                    <Heading medium>Nazwa banku:</Heading>
                      <StyledInput card
                          type="text"
                          name="bank_name"
                          placeholder={values.email}
                          onChange={this.handleChangeValue}
                          onBlur={handleBlur}
                          value={values.bank_name}
                        
                          />
                  
                    </StyledInputWrapper>

                    <StyledInputWrapper>
                    <Heading medium>Nr konta:</Heading>
                      <Input card
                          type="text"
                          name="account_number"
                          placeholder={values.email}
                          onChange={this.handleChangeValue}
                          onBlur={handleBlur}
                          value={values.account_number}
                        
                          />
                  
                    </StyledInputWrapper>

                 </StyledInnerWrapper>

              </InnerWrapper>
              
              <Buttons pagecolor={pageContext.pageColor} activeColor={pageContext.sidebarColor}>
              
                <Button type="button"
                as={NavLink} to={routes.settings.users.home} medium back >
                {pageContext.t('button.back')}
                </Button>
    
                <Button type="submit" medium mediumaccept activecolor={pageContext.sidebarColor} >
                {pageContext.t('button.save')}
                </Button>
              </Buttons>
              </Form>
            )}
            </Formik>
          </StyledCard>
          <QuestionnaireMenu pageContext={pageContext} authuserinfo={authuserinfo} id={id}/>
          </>
          :   ''}
          </StyledWrapper>
      
         
        )
    }
}

const mapStateToProps = state => {

  const { alert,message, workplaces, authuserinfo, getuserinfo } = state;

  return { alert,message, workplaces, authuserinfo, getuserinfo};
};

const mapDispatchToProps = dispatch => ({
    getUserInfo: (id) => dispatch(getUserInfoAction(id)),
    editQuestionnaire: (values,id) => dispatch(editQuestionnaireAction(values,id)),
 
  clearMessage: () =>dispatch(clearMessageAction())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withContext(AddUserForm));