import React, { Component }  from 'react'
import styled from 'styled-components';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const StyledTooltip=styled(Tooltip)`
  font-size: ${({theme}) => theme.fontSize.xs};
  
    .tooltip-inner{
        background-color:${({ activeColor}) => activeColor};
        color:white;
       
    }
`;

const TooltipBox = ({activeColor,text, info}) =>{

 
    return (
        <OverlayTrigger
          placement="left"
          overlay={
            <StyledTooltip activeColor={activeColor} id={`tooltip-left`}>
            {info}
            </StyledTooltip>
         } >
        <div>{text!='0:0' ? text : 0}</div>
       </OverlayTrigger>

    )
}

export default TooltipBox;


